<template>
  <div class="container">
    <div class="inner-content">
      <div class="about-promo about-promo-bg d-flex justify-content-center align-items-center">
        <div class="content">
          <p class="title">Условия и положения</p>
          <p>Условия сервиса для клиентов и фотографов</p>
        </div>
      </div>
      <div class="front-about">
        <h1>Документы</h1>
        <p><a href="/assets/docs/offer.pdf" target="_blank">Договор – оферта на оказание информационно-справочных
          услуг</a></p>
        <p><a href="/assets/docs/personal-data-processing-policy.pdf" target="_blank">Политика обработки персональных
          данных</a></p>
        <p><a href="/assets/docs/terms-of-use.pdf" target="_blank">Пользовательское соглашение</a></p>
        <p><a href="/assets/docs/returns.pdf" target="_blank">Правила и порядок возврата</a></p>
        <p><a href="/assets/docs/requisites.pdf" target="_blank">Банковские реквизиты</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rule"
}
</script>

<style scoped>

</style>