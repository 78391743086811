<template>
	<div>
		<div class="row">
			<div class="col-12 col-md-6" v-for="(card, key) in cards">
				<div class="form-group">
					<label>{{$t('lk.payment_area.card')}}</label>
					<div class="input-group">
						<div class="input-group-prepend">
							<div class="input-group-text">
								<div class="logo" :class="logo(card.type)"></div>
							</div>
						</div>
						<the-mask
								class="form-control form-control-md"
								:mask="['#### #### #### ####']"
								v-model="card.number"></the-mask>
						<!--                            <input type="text" class="form-control form-control-md" placeholder="1234 5678 1234 0000" v-model="card.number">-->
						<div class="input-group-append">
							<div class="input-group-text" v-on:click="deleteCardModal()">
								{{$t('lk.payment_area.delete')}}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-md-6">
				<div class="form-group">
					<label> {{$t('lk.payment_area.add_card')}}</label>
					<div class="btn btn-secondary btn-block btn-text-light" v-b-modal.modal-add-card>
						{{$t('lk.payment_area.add_card')}}</div>
				</div>
			</div>
		</div>

		<b-modal id="modal-add-card" centered hide-header hide-footer>
			<div class="modal-header">
				<p class="modal-title modal-title-sm">Добавление новой карты</p>
				<div class="close" @click="addCardModal"></div>
			</div>
			<div class="form-group">
				<label>Номер карты</label>
				<the-mask
						class="form-control"
						:mask="['#### #### #### ####']"
						placeholder="0000 0000 0000 0000"
						v-model="newCard.number"></the-mask>
<!--				<input type="text" name="card-number" v-model="newCard.number" class="form-control" placeholder="0000 0000 0000 0000">-->
			</div>
			<div class="form-group">
				<label>Срок действия</label>
				<div class="form-row align-items-center">
					<div class="col">
						<input type="text" name="card-month"  v-model="newCard.month" class="form-control" placeholder="MM">
					</div>
					<div class="col-auto">
						/
					</div>
					<div class="col">
						<input type="text" name="card-year" v-model="newCard.year" class="form-control" placeholder="YY">
					</div>
				</div>
			</div>
			<div class="form-group">
				<label>Имя владельца</label>
				<input type="text" name="card-name" v-model="newCard.cardholder" class="form-control" placeholder="CARDHOLDER NAME">
			</div>
			<div class="form-group">
				<label>CVC</label>
				<input type="password" name="card-cvc" class="form-control" placeholder="***">
			</div>
			<button type="submit" class="btn btn-primary btn-block" v-on:click="addCard">Добавить</button>
		</b-modal>

		<b-modal id="modal-delete-card" centered hide-header hide-footer>
			<div class="modal-header">
				<p class="modal-title modal-title-sm">Вы хотите удалить карту?</p>
			</div>
			<p>Подтвердите действие</p>
			<div class="row">
				<div class="col">
					<button type="submit" class="btn btn-secondary btn-block" v-on:click="deleteCard()">Удалить</button>
				</div>
				<div class="col">
					<button type="submit" class="btn btn-primary btn-block" v-on:click="deleteCardModalClose()">
						Отменить
					</button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import {BModal} from 'bootstrap-vue';
	import {TheMask} from 'vue-the-mask'

	export default {
    name: "Card",
    props:{
        cards:{
            type:Array,
            default: ()=>{
                return []
            }
        }
    },
		data() {
			return {
				cardTemplate: {
					number: '',
					month: '',
					year: '',
					cardholder: '',
					type: 'visa'
				},
				newCard: {},
				cardToDelete: undefined,
				cardKey: undefined,
			}
		},
    components:{TheMask, BModal},
    methods:{
        logo(type){
            return 'logo-'+type.toLowerCase();
        },
		addCard(){
			this.cards.push(this.newCard);
		},
		deleteCard(){
			this.cards.splice(this.cardKey, 1);
			this.$bvModal.hide('modal-delete-card');
			this.cardToDelete = false;
		},
		addCardModal(){
			this.$bvModal.hide('modal-add-card');
			this.newCard = JSON.parse(JSON.stringify(this.cardTemplate))
		},
		deleteCardModal(card, key){
			this.$bvModal.hide('modal-delete-card');
			this.cardToDelete = card;
			this.cardKey = key;
		},
		deleteCardModalClose(){
			this.$bvModal.hide('modal-delete-card');
			this.cardToDelete = undefined;
			this.cardKey = undefined;
		}
    }
}
</script>

<style scoped>

</style>