<template>
    <div>
        <b-form-tags v-model="value[target]" no-outer-focus v-if="!withSelect">
            <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                <b-form-input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :placeholder="placeholder"
                        class="form-control form-control-sm"
                ></b-form-input>
                <p class="form-text text-muted">{{ subText }}</p>
                <ul class="tags-list no-list">
                    <b-form-tag
                            v-for="tag in tags"
                            tag="li"
                            @remove="removeTag(tag)"
                            :key="tag"
                            :title="tag"
                    >{{ tag }}
                    </b-form-tag>
                </ul>
            </template>
        </b-form-tags>
        <b-form-group v-else>
            <b-dropdown no-flip :text="getText ? getText : placeholder" class="form-dropdown form-dropdown-sm form-dropdown-checkbox-block">
                <b-dd-text v-bind:key="option" v-for="option in selectList.filter(list_option => list_option.active === true || list_option.active === undefined)">
                    <div class="dropdown-checkbox"
                         :class="{active:value[target].indexOf(option) !== -1,disable: !value[target].indexOf(option) && value[target].length >= limit}"
                         v-on:click="toggleTag(option)">{{$t('lists.'+target+'.'+option)}}
                    </div>
                </b-dd-text>
            </b-dropdown>
            <p class="form-text text-muted">{{ subText }}</p>
            <ul class="tags-list no-list">
                <li
                        v-for="tag in value[target]"
                        :key="tag"
                        :title="tag"
                >{{$t('lists.'+target+'.'+tag)}}
                    <div v-on:click="removeTag(tag)" class="close"></div>
                </li>
            </ul>
        </b-form-group>
    </div>

</template>

<script>
import {BFormInput, BFormTag, BFormTags} from 'bootstrap-vue';

export default {
    name: "Tag",
    props: {
        value: {},
        target: {},
        placeholder: {
            type: String
        },
        subText: {
            type: String
        },
        withSelect: {
            type: Boolean,
            default: false,
        },
        selectList: {
            type: Array,
            default() {
                return []
            }
        },
        limit: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {}
    },
    computed: {
        availableOptions() {
            return this.selectList.filter(opt => this.value[this.target].indexOf(opt) === -1)
        },
        getText() {
            if (this.value[this.target].length > 0) {
                let objects = [];
                this.value[this.target].forEach(obj => objects.push(this.$t('lists.' + this.target + '.' + obj)));
                return objects.join(', ');
            } else {
                return false;
            }

        },
    },
    components: {
        BFormTags, BFormTag, BFormInput
    },
    methods: {

        removeTag(tag) {
            let index = this.value[this.target].indexOf(tag);
            this.value[this.target].splice(index, 1);
        },
        toggleTag(tag) {
            // let index = this['value'][this.target].indexOf(tag);
            let index = this.value[this.target].indexOf(tag);
            if (index === -1) {
                if (this.limit > 0 && this.value[this.target].length >= this.limit) {
                    return;
                }
                this.value[this.target].push(tag);
                this.$forceUpdate();
            } else {
                this.value[this.target].splice(index, 1);
            }
        }
    }
}
</script>

<style scoped>

</style>