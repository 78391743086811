<!--<template>-->
<!--  &nbsp;-->
<!--</template>-->

<script>
export default {
  name: "VKPixel",
  mounted() {
    if (process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false') {
      this.$root.$on('press_vk_button', (eventName, id) => {
        try {
          VK.Retargeting.Event(eventName);
          VK.Retargeting.Add(id);
        } catch (error) {
          // console.log(error);
        }
      });
    }
  }
}
if (process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false') {
  !function () {
    var t = document.createElement("script");
    t.type = "text/javascript", t.async = !0, t.src = "https://vk.com/js/api/openapi.js?168", t.onload = function () {
      VK.Retargeting.Init("VK-RTRG-540950-euUGV"),
          VK.Retargeting.Hit()
    }, document.head.appendChild(t)
  }();
}
</script>

<noscript v-if="process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false'"><img src="https://vk.com/rtrg?p=VK-RTRG-540950-euUGV" style="position:fixed; left:-999px;" alt=""/></noscript>


<style scoped>

</style>