import Api from "../components/services";

export class Place {
    api= new Api();
    store = undefined;
    vue = undefined;
    city = '';
    reset = false;

    constructor(store, vue) {
        this.store = store;
        this.vue = vue;
        this.city = this.vue.$route.query.city
        if (localStorage.getItem('user.store.place')) {
            this.store.city = JSON.parse(localStorage.getItem('user.store.place'));
            this.store.raw = JSON.parse(localStorage.getItem('user.store.place_raw')); //TODO
        } else if (localStorage.getItem('user.store.place_raw')) {
            this.store.raw = JSON.parse(localStorage.getItem('user.store.place_raw'));
            this
                .getCityData().then((data) => {
                this.store.city = data.data;
            });
        } else if (localStorage.getItem('user.store.options')) {
            let options = JSON.parse(localStorage.getItem('user.store.options'));
            this.store.city = options.city;
        }
    }

    setRawData(raw) {
        this.reset = false;
        this.store.raw = JSON.parse(JSON.stringify(raw));
        this
            .getCityData().then((data) => {
            this.store.city = data.data.data;
            delete  this.store.city.raw; //пока работаем с нашей базой городов убираем хак
            localStorage.setItem('user.store.place', JSON.stringify(this.store.city));
            localStorage.setItem('user.store.place_raw', JSON.stringify(this.store.raw));

            this.vue.$root.$emit('update-place');
        });
    }

    getRawData() {
        return this.store.raw != null ? this.store.raw : false;
    }

    getCityData() {
        let locality = this.getRawData().address_components.filter(addres => addres.types.indexOf('locality') > -1)[0];
        return this.api
            .searchTown({city_name:locality.short_name, city_place_id:this.getRawData().place_id})
    }
    clear(){
        this.reset = true;
    }
    getCity() {
        return this.store.city;
    }
    getCoordinates() {
        return this.getRawData() && this.getRawData().geometry ? this.getRawData().geometry.location : undefined;
    }
    getLocations(){
        let loc = {
            lat: 0,
            lng: 0
        };
        if(typeof this.getRawData().geometry.location.lat === 'function'){
            loc.lat =  this.getRawData().geometry.location.lat();
        }else{
            loc.lat = this.getRawData().geometry.location.lat;
        }
        if(typeof this.getRawData().geometry.location.lng === 'function'){
            loc.lat =  this.getRawData().geometry.location.lng();
        }else{
            loc.lng = this.getRawData().geometry.location.lng;
        }

        return loc;
    }
    getName(){
        if(this.reset){
            return  '';
        }
        return this.city ?  this.city : this.getRawData() ? this.getRawData().formatted_address : '';
    }
}