import { render, staticRenderFns } from "./MobileOrder.vue?vue&type=template&id=682aec16&scoped=true&"
import script from "./MobileOrder.vue?vue&type=script&lang=js&"
export * from "./MobileOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "682aec16",
  null
  
)

export default component.exports