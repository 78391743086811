var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-map"},[_c('GmapMap',{attrs:{"center":_vm.marker,"zoom":10,"options":{
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false
			}}},[_c('GmapMarker',{attrs:{"position":_vm.marker,"clickable":false,"icon":_vm.icon}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }