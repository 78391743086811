<template>
<div>
  <component
      src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"
      :is="'script'"
  ></component>
<!--  <button v-on:click="pay()">asd</button>-->
</div>
</template>
<script>
export default {
  name: "CloudpaymentForm",
  props:{
    paymentConfig:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
  },
  mounted() {
    // this.$root.$emit('load-modules', {});
  },
  methods:{
    pay(){
      var widget = new cp.CloudPayments();
      // { //options
      //   publicId: 'pk_c677d5399b1d5da8ca556d9cf3719', //id из личного кабинета
      //       description: 'Оплата товаров в snappy', //назначение
      //     amount: 100, //сумма
      //     currency: 'RUB', //валюта
      //     accountId: 'kriokris@gmail.com', //идентификатор плательщика (необязательно)
      //     invoiceId: 'asdqwe1234', //номер заказа  (необязательно)
      //     email: 'kriokris@gmail.com', //email плательщика (необязательно)
      //     skin: "mini", //дизайн виджета (необязательно)
      //     autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
      //     data: {
      //   myProp: 'myProp value'
      // },
      //   configuration: {
      //     common: {
      //       successRedirectUrl: "https://snappy.sealed.company/success", // адреса для перенаправления
      //           failRedirectUrl: "https://snappy.sealed.company/fail"        // при оплате по T-Pay
      //     }
      //   },
      //   payer: {
      //     firstName: 'Тест',
      //         lastName: 'Тестов',
      //         middleName: 'Тестович',
      //         birth: '1955-02-24',
      //         address: 'тестовый проезд дом тест',
      //         street: 'Lenina',
      //         city: 'MO',
      //         country: 'RU',
      //         phone: '123',
      //         postcode: '345'
      //   }
      // }
      // 'pk_c677d5399b1d5da8ca556d9cf3719'
      widget.pay('auth', // или 'charge'
          this.paymentConfig
         ,
          {
            onSuccess: function (options) { // success
              // console.log('success', options);
              // window.locale.href = '/personal_area/order';
            },
            onFail: function (reason, options) { // fail
              // console.log('fail',options);
              //действие при неуспешной оплате
            },
            onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              // console.log('complete',options);
              //например вызов вашей аналитики
              // window.locale.href = '/personal_area/order';
            }
          }
      )
    }
  }
}
</script>
<style scoped>

</style>