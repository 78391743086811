<template>
  <div class="inner-content" v-if="personal">
    <p class="h2">{{ $t('lk.profile_area.photo_in_profile') }}</p>
    <div class="account-avatar-uploader d-flex flex-column flex-md-row align-items-center">
      <div class="avatar" v-if="personal && personal.avatars" :style="{
                        backgroundImage: `url(${personal ? personal.avatars['thumb-260'] : ''})`,
                        }"></div>
      <div class="avatar no-avatar" v-else></div>
      <div>
        <div class="content text-center text-md-left">
          <p>{{ $t('lk.profile_area.info_1') }}</p>
        </div>
        <input ref="file" type="file" v-on:change="handleFileUpload()" id="avatar-upload" class="d-none">
        <label class="btn btn-primary btn-block" for="avatar-upload">{{ $t('lk.profile_area.upload_photo') }}</label>
      </div>
    </div>

    <div class="account-separator line"></div>

    <p class="h2">{{ $t('lk.profile_area.profile_info') }}</p>
    <p class="h2-lead text-muted">{{ $t('lk.profile_area.profile_info_2') }}</p>
    <div class="form-group">
      <label>{{ $t('lk.profile_area.current_city') }}</label>
      <div v-b-modal.modal-choose-city-main>
        <autocomplete-city :city="profile.user_location.city"></autocomplete-city>
        <!--                <input type="text" class="form-control form-control-sm" :value="profile.user_location.city[$store.state.user.options.locale]" v-if="profile.user_location" disabled>-->
      </div>
    </div>

    <div v-if="isPhotographer()">
      <div class="account-separator-sm line"></div>
      <div class="form-group" v-for="(town, key) in profile.towns">
        <label>{{ $t('lk.profile_area.optional_city') }}</label>
        <div class="input-group">
          <autocomplete-city :city="town"></autocomplete-city>
          <!--                    <input type="text" class="form-control form-control-sm" :value="town[$store.state.user.options.locale]" disabled>-->
          <div class="input-group-append">
            <span class="input-group-text pointer"
                  v-on:click="profile.towns.splice(key, 1)">{{ $t('lk.profile_area.delete') }}</span>
          </div>
        </div>
      </div>

      <button class="btn btn-secondary btn-block btn-text-medium" v-on:click="addCity()">
        {{ $t('lk.profile_area.add_orders_city') }}
      </button>
      <!--            <button class="btn btn-secondary btn-block btn-text-medium" v-b-modal.modal-choose-city>{{$t('lk.profile_area.add_orders_city')}}</button>-->
      <!--            <cities-popup ref="cities" :selected-cities="profile.towns" :is-multiple="true"></cities-popup>-->
      <div class="account-separator line"></div>

      <p class="h2">{{ $t('lk.profile_area.about_yourself') }}</p>
      <p class="h2-lead text-muted">{{ $t('lk.profile_area.about_info') }}</p>
      <div class="form-group">
        <textarea class="form-control form-control-xl" v-model="profile.about"
                  :placeholder="$t('lk.profile_area.about_info_2')"></textarea>
      </div>

      <div class="account-separator"></div>

      <p class="h2">{{ $t('lk.profile_area.add_info') }}</p>
      <div class="form-group">
        <div class="custom-control custom-checkbox-block">
          <input type="checkbox" name="checkbox" id="checkbox-distance" v-model="profile.is_remote"
                 class="custom-control-input">
          <label for="checkbox-distance" class="custom-control-label">Дистанционная фотосъемка</label>
        </div>
      </div>
      <div class="form-group">
        <label>{{ $t('lk.profile_area.equipment') }}</label>
        <tag v-model="profile" target="equipment"
             :with-select="true"
             :select-list="$store.state.list.equipment"
             :placeholder="$t('lk.profile_area.choose_options')"
             :subText="$t('lk.profile_area.equipment_info')"></tag>
      </div>
      <div class="account-separator-sm"></div>
      <!--            <div class="form-group">-->
      <!--                <label>{{$t('lk.profile_area.photos_ready')}}</label>-->
      <!--                <tag v-model="profile" target="photo_readiness"-->
      <!--                     :with-select="true"-->
      <!--                     :select-list="$store.state.list.photo_readiness"-->
      <!--                     :placeholder="$t('lk.profile_area.choose_options')"-->
      <!--                     :subText="$t('lk.profile_area.photos_ready_info')"></tag>-->
      <!--            </div>-->
      <div class="account-separator-sm"></div>
      <div class="form-group">
        <label>{{ $t('lk.profile_area.lang') }}</label>
        <tag v-model="profile" target="languages"
             :with-select="true"
             :select-list="$store.state.list.languages"
             :placeholder="$t('lk.profile_area.choose_options')"
             :subText="$t('lk.profile_area.lang_info')"></tag>
      </div>
      <div class="account-separator-sm"></div>
      <div class="form-group">
        <label>{{ $t('lk.profile_area.add_services') }}</label>
        <tag v-model="profile" target="additional_services"
             :with-select="true"
             :select-list="$store.state.list.additional_services"
             :placeholder="$t('lk.profile_area.choose_options')"
             :subText="$t('lk.profile_area.add_services_info')"></tag>
      </div>
      <div class="account-separator-sm"></div>
      <!--            <div class="form-group">-->
      <!--                <label>{{$t('lk.profile_area.session_type')}}</label>-->
      <!--                <tag v-model="profile" target="photosession_types"-->
      <!--                     :with-select="true"-->
      <!--                     :select-list="$store.state.list.sessions"-->
      <!--                     :placeholder="$t('lk.profile_area.choose_options')"-->
      <!--                     :subText="$t('lk.profile_area.session_type_info')"></tag>-->
      <!--            </div>-->
      <div class="account-separator-sm"></div>
      <div class="form-group">
        <label>{{ $t('lk.profile_area.locations') }}</label>
        <tag v-model="profile" target="locations"
             :with-select="true"
             :select-list="$store.state.list.locations"
             :placeholder="$t('lk.profile_area.choose_options')"
             :subText="$t('lk.profile_area.locations_info')"></tag>
      </div>
    </div>
    <div class="account-separator"></div>
    <fixed-button-panel ref="buttons">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <button class="btn btn-secondary btn-block" v-on:click="load">{{ $t('lk.profile_area.cancel') }}</button>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <button class="btn btn-primary btn-block"   :class="{'disabled' : isEqual(profile, old_profile) && isEqual(personal, old_personal)}" v-on:click="save">
            {{ $t('lk.profile_area.send.' + personal.role) }}
          </button>
        </div>
      </div>
    </fixed-button-panel>


  </div>
</template>

<script>
import Api from '../../service';
import Tag from "../widget/Tag";
import CitiesPopup from "../../../widgets/CitiesPopup";
import AutocompleteCity from "../../../widgets/AutocompleteCity";
import FixedButtonPanel from "@/components/lk/components/widget/FixedButtonPanel";

let api = undefined;
export default {
  name: "Profile",
  data() {
    return {
      file: undefined,

      personal: undefined,
      old_personal: undefined,
      profile: {},
      old_profile: {},
      value: [],
      options: [],
      loadModules: {
        profile: false,
      }
    }
  },
  components: {FixedButtonPanel, AutocompleteCity, Tag},
  mounted() {
    api = new Api();
    this.load();
  },
  watch: {
    loadModules: {
      handler: function (newValue) {
        this.$root.$emit('load-modules', newValue);
      },
      deep: true
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.saveAvatar();
    },
    addCity() {
      if (this.profile.towns.filter(town => town.id === '').length <= 0) {
        this.profile.towns.push({
          id: '',
          en: '',
          ru: ''
        })
      }

    },
    isEqual(first, second) {
      return JSON.stringify(first) === JSON.stringify(second);
    },
    load() {
      api.lists();
      api
          .getProfile()
          .then((res) => {
            this.profile = res.data.data.profile;
            this.old_profile = this.clone(this.profile);
            this.personal = res.data.data.personal;
            this.old_personal =  this.clone(this.personal);
            this.loadModules.profile = true;
            setTimeout(()=>{
              this.$refs.buttons.$emit('load');
            }, 100);
          })
          .catch((error) => {
            // this.$root.$emit('error', error.response.data.message);
            this.loadModules.profile = true;
          });
    },
    saveAvatar() {
      if (this.file) {
        api
            .updateAvatar(this.file)
            .then((res) => {
              setTimeout(()=>{
                this.personal = res.data.data.personal;
                this.$root.$emit('successful', 'Новая аватара добавлена');
              },3000);

            })
            .catch((error) => {
              this.$root.$emit('error', error.response.data.message);
            });
      }
    },
    save() {
      api
          .updateProfile(this.profile)
          .then((res) => {
            this.profile = res.data.data.profile;
            this.old_profile = this.clone(res.data.data.profile);
            this.personal = res.data.data.personal;
            this.old_personal =  this.clone(res.data.data.personal);
            this.$root.$emit('successful', 'Сохраненно');
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
          });


    }

  }
}
</script>

<style scoped>

</style>