<template>
    <b-modal :id="id" centered hide-header hide-footer v-model="state">
        <div class="city-search">
            <input type="text" class="form-control form-control-xs" placeholder="Поиск" v-model="search">
        </div>
        <div class="scroll-vertical">
            <div class="scroll-inner">
                <ul class="city-list no-list">
                    <li v-if="selectedCity" class="active">
                        <div>{{selectedCity[$store.state.user.options.locale]}}</div>
                    </li>
                    <li v-for="city in selectedCities" class="active">
                        <div>{{city[$store.state.user.options.locale]}}</div>
                    </li>

                    <li v-for="city in cities" v-on:click="select(city)">
                        <div>{{city[$store.state.user.options.locale]}}</div>
                        <div>
                            <button class="btn btn-bordered btn-xs btn-text-medium">Выбрать</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Api from "../services";

export default {
    name: "CitiesPopup",
    props: {
        id: {
            type: String,
            default: 'modal-choose-city'
        },
        isMultiple: {
            type: Boolean,
            default: false,
        },
        selectedCity: {
            type: Object,
            default() {
                return undefined;
            }
        },
        selectedCities: {
            type: Array,
            default() {
                return [];
            }
        },
        deleteButton: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            state: false,
            search: '',
            cities: []
        }
    },
    watch: {
        search: {
            handler: function (newValue) {
                if (newValue !== '') {
                    const myPattern = new RegExp('(\\w*' + newValue + '\\w*)', 'gi');
                    this.cities = this.$store.state.list.cities.filter(city => city.ru.match(myPattern) || city.en.match(myPattern));
                } else {
                    this.cities = this.$store.state.list.cities;
                }
            }
        },
        state: {
            handler: function (newValue) {
                if (newValue) {
                    if (this.isMultiple) {
                        this.cities = this.$store.state.list.cities.filter((city) => {
                            return this.selectedCities.indexOf(city) < 0;
                        });

                    } else {
                        this.cities = this.$store.state.list.cities.filter(city => city.id !== this.selectedCity.id);
                    }

                }
            }
        }
    },
    mounted() {
        let api = new Api();
        api.cities();
    },
    methods: {

        select(city) {
            this.$emit('add-city', city);
            // if (!this.isMultiple) {
            this.$bvModal.hide(this.id);
            // }

        },

    }
}
</script>

<style scoped>

</style>