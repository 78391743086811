<template>
    <div id="scroll-top" v-on:click="handleClick()" v-if="isActive"></div>
</template>

<script>
import SweetScroll from 'sweet-scroll';

const scroller = new SweetScroll();
export default {
    name: "GoTop",
    data() {
        return {
            boundary: 300,
            isActive: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isActive = window.pageYOffset > this.boundary;
        },
        handleClick() {
            scroller.to(0);
        }
    },
    mounted() {
        this.$root.$on('go-top', ()=>{
            this.handleClick();
        })
    }
}
</script>

<style scoped>

</style>