<template>
    <div class="autocomplete-wrapper">
        <GmapAutocomplete
                ref="autocomplete"
                :selectFirstOnEnter="true"
                @place_changed="setPlace"
                :options="options"
                :value="getPlaceName()"
                class="form-control form-icon icon-location"
        ></GmapAutocomplete>
        <div class="clear" v-on:click="clear()"></div>
    </div>
</template>

<script>
export default {
    name: "AutocompleteCity",
    props: {
        city: {
            type: Object,
            default() {
                return {
                    id: '',
                    en: '',
                    ru: ''
                }
            }
        }
    },
    data() {
        return {
            options: {
                types: ['(cities)'],
            }
        }
    },
    mounted() {

    },
    watch: {},
    methods: {

        setPlace(place) {
            this.clear();
            let locale = this.$store.state.user.options.locale;
            this.city.id = place.place_id;
            this.city[locale] = place.name;
        },
        clear() {
            this.city.id = "";
            this.city.en = "";
            this.city.ru = "";

        },
        getPlaceName() {
            return this.city[this.$store.state.user.options.locale];
            // return this.$place.getName();
        },

        getRaw() {
            // return this.$place.getRawData();
        }
    }
}
</script>

<style scoped>

</style>