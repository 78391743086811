import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue, {ModalPlugin} from 'bootstrap-vue';
import store from './store/main';
import {i18n} from './i18n'
import VueMoment from 'vue-moment'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import MainLayout from './layouts/MainLayout'
import ShortLayout from "./layouts/ShortLayout";
import Vue2Filters from 'vue2-filters'
import './_helpers/order'
import './_helpers/role'
import './_helpers/common'
import {Place} from "./services/Place";
import money from 'v-money'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueTelInput from 'vue-tel-input'
import VueGtm from "vue-gtm";
import VGoogleTranslate from "./components/widgets/translate/index";

Vue.use(VueTelInput);
Vue.use(VGoogleTranslate);

Vue.use(money, {
  decimal: ',',
  thousands: ' ',
  precision: 0
})

Vue.use(Vue2Filters);
Vue.component('main-layout', MainLayout);
Vue.component('short-layout', ShortLayout);
Vue.config.productionTip = false;
Vue.use(VueMoment, {
  moment
});
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.use(require('@websanova/vue-auth'), {
  parseUserData: function (data) {
    store.commit('user/auth', this.token());
    store.commit('user/load', data.data);
    return data.data.personal;
  },
  auth: require('./_helpers/auth_token.js'),
  http: require('@websanova/vue-auth/drivers/http/vue-resource.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  rolesVar: 'role',
  tokenDefaultName: 'snappy_auth_token',
  refreshData: {
    enabled: false
  },
  tokenExpired: function (data) {
    // console.log(data);
  },
  facebookOauth2Data: {
    clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
    scope: ['email'],
    response_type: "token",
  },
});
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    language: localStorage.getItem('user.store.options') ? JSON.parse(localStorage.getItem('user.store.options')).locale : 'ru',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YANDEX_METRIKA,
  env: process.env.NODE_ENV,
  router: router,
  debug:process.env.VUE_APP_YANDEX_METRIKA_DEBUG ?? false,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
    ecommerce:"dataLayer"
  }
})
if(process.env.VUE_APP_GMT){
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GMT
  });
}


let VueApp = new Vue({
  render: h => h(App),
  i18n,
  store,
  router,
  beforeCreate() {
    store.commit('user/auth', this.$auth.token());
    this.$store.commit('user/init');
    if (!this.$route.meta.withLoader || this.$route.meta.emulateLoader) {
      setTimeout(() => {
        this.$root.$emit('load-modules', {})
      }, 200);
    }
  },
});


VueApp.$router.beforeEach((to, from, next) => {
  if (to.meta.withLoader || to.meta.emulateLoader) {
    VueApp.$root.$emit('load-start');
  }
  setTimeout(() => {
    next();
  }, 1000);
});
Vue.config.silent = true;
VueApp.$router.afterEach((to) => {

  if (!to.meta.withLoader || to.meta.emulateLoader) {
    setTimeout(() => {
      VueApp.$root.$emit('load-modules', {})
    }, 200);
  }
});

Vue.prototype.$place = new Place(store.state.place, VueApp);

VueApp.$mount('#app');
export default VueApp;
