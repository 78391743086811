<template>
  <b-modal id="modal-before-order-redirect" centered hide-header hide-footer>
    <div class="modal-header border-bottom">
      <p class="modal-title modal-brand modal-title-sm">Перенаправление на платежную страницу</p>
      <!--      <div class="close" @click="$bvModal.hide('modal-reserve-photographer')"></div>-->
    </div>
    <div>
      <p>30% оплачиваются сейчас для подтверждения бронирования. Остальная сумма – оплачивается фотографу после
        проведения съемки.</p>
      <p>Оплата возвращается, если заказ не принят, или вы передумали за 24 часа до съемки.</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "OrderInfoModal"
}
</script>

<style scoped>

</style>