<template>
    <div class="map-container">
        <GmapMap ref="map"
                 :center="getUserLocation()"
                 :zoom="10"
                 :options="{
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false
			}"
        >
            <GmapCustomMarker
                    :marker="getUserLocation()"
                    :z-index="75"
                    class="user-location-block">
                <div class="user-location"></div>
            </GmapCustomMarker>
            <GmapCustomMarker
                    v-bind:key="index"
                    v-for="(user, index) in users"
                    :marker="getMarkerPosition(user)"
                    alignment="right"
                    :z-index="activeMarker === user.personal.id ? 100 : 50"
                    class="photographer-marker-block"
                    :class="{active:activeMarker === user.personal.id}">
                <div class="photographer-marker" :class="{available: !user.is_busy}" v-on:click="activeMarker=user.personal.id">
                    <div class="avatar" :style="{backgroundImage: `url(${user.personal.avatars['thumb-166']})`}"></div>
                    <div class="price">{{user.price}} <i class="rub">Р</i></div>
                    <div class="photographer-marker-info">
                        <div class="photographer-item-slider">
                            <carousel :navText="['prev','next']" :loop="true" :items="1" :nav="true" :dots="true" :autoplay="true">
                                <div
                                        v-bind:key="index"
                                        class="slide"
                                        :style="{backgroundImage: `url(${header.path.profile})`}" v-for="(header, index) in user.headers">
                                </div>
                            </carousel>
                        </div>
                        <div class="d-flex justify-content-between align-items-center photographer-marker-line">
                            <div class="name">
                                {{getName(user.personal)}}
                            </div>
                            <div>
                                <div class="favourite-button pointer" v-on:click="addFavorite(user)" :class="{active:user.profile.is_favorited}"></div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center photographer-marker-line">
<!--                            <div>-->
<!--                                <div class="status" :class="{available: !user.is_busy}">{{ !user.is_busy ? 'Свободен' : 'Занят'}}</div>-->
<!--                            </div>-->
                            <div>
                                <div class="rating">{{user.personal.rating.avg}}</div>
                            </div>
                        </div>
                        <router-link :target="openInNewPage ? '_blank' : '_self'"  class="photographer-marker-info-link" :to="{name:'photographer_profile',params:{id:user.personal.id}}" v-if="!user.is_busy"></router-link>
                        <!--router-link :target="openInNewPage ? '_blank' : '_self'"  class="btn btn-block btn-xs btn-secondary" :to="{name:'photographer_profile',params:{id:user.personal.id}}" v-if="user.is_busy">Профиль</router-link-->
                        <button class="btn btn-block btn-xs btn-primary"
                                v-on:click="$root.$emit('create-order', user)" v-if="!user.is_busy">Заказать съемку</button>
                    </div>
                </div>
            </GmapCustomMarker>
        </GmapMap>
        <div class="zoom-control">
            <button class="zoom-in" v-on:click="$refs.map.zoom++">+</button>
            <hr>
            <button class="zoom-out" v-on:click="$refs.map.zoom--">+</button>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import SearchApi from "./services";

let api = undefined;
export default {
    name: "SearchMap",
    props: {
        openInNewPage:{
            type:Boolean,
            default: true
        },
        users: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            activeMarker: undefined,
            icon: {
                anchor: {x: 10, y: 27},
                labelOrigin: {x: 0, y: 0},
                origin: {x: 0, y: 0},
                scaledSize: {width: 25, height: 31},
                size: {width: 50, height: 62},
                url: "/assets/images/map-marker.png",
            }
        }
    },
    methods: {
        getMarkerPosition(user){
          return  user.profile.position;
          },

        getUserLocation() {
            return this.$place.getCoordinates()? this.$place.getCoordinates(): this.users.length > 0 ? this.users[0].profile.position : {lat: 55.7558260, lng: 37.6172999}
        },
        addFavorite(user) {
            if (this.$auth.check() !== true) {
                this.$bvModal.show('modal-login');
                return;
            }
            api
                .toggleFavorite(user.personal.id)
                .then((res) => {
                    user.profile.is_favorited = res.data.data.is_favorited;
                })
        },
    },
    mounted() {
        api = new SearchApi();
    },
    components: {GmapCustomMarker, carousel},
}
</script>

<style scoped>

</style>