<template>
    <div class="inner-content">
        <h1>{{$t('lk.favourite_area.favourite')}}</h1>

        <div class="photographer-item-list">
            <div class="row">
                <div class="col-12 col-xl-6" v-for="(profile, key) in profiles">
                    <div class="photographer-item">
	                    <div class="d-flex flex-column flex-md-row flex-xl-column">
                            <div class="photographer-item-slider">
                                <carousel :navText="['prev','next']" :loop="true" :items="1" :nav="true" :dots="false">
                                    <div class="slide" :style="{
                                                            backgroundImage: `url(${header.path.profile})`,
                                                        }" v-for="header in profile.headers">

                                    </div>
                                </carousel>
                            </div>
                            <div class="d-flex flex-column w-100">
                                <div class="d-flex align-items-center user">
                                    <div>
                                        <div class="avatar" :style="{
                                            backgroundImage: `url(${profile.personal.avatars['thumb-166']})`,
                                            }"></div>
                                    </div>
                                    <div class="name">
                                        {{profile.personal.name}} {{profile.personal.nickname}}
                                    </div>
                                    <div class="ml-auto">
                                        <div class="favourite-button active pointer" v-on:click="removeFavorite(profile.personal.id, key)"></div>
                                    </div>
                                </div>
                                <div class="info">
                                    <div class="row justify-content-between">
                                        <div class="col-6">
                                            <div class="photos">{{profile.personal.photosessions}} фотоссесии</div>
                                        </div>
                                        <div class="col-6">
                                            <div class="camera">{{ profile.profile.equipment[0]}}</div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="tags no-list">
                                    <li  v-for="service in profile.profile.additional_services">{{service}}</li>
                                </ul>
                                <div class="photographer-bottom d-flex mt-auto justify-content-between align-items-center mt-auto mt-auto">
                                    <div>
                                        <div class="rating">{{profile.personal.rating.avg}} <span>({{profile.personal.rating.count}})</span></div>
                                    </div>
                                    <div>
                                        <div class="price">3000 <span><i class="rub">Р</i> / час</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <router-link :to="{name:'photographer_profile',params:{id:profile.personal.id}}" class="photographer-item-link"></router-link>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import Api from '../../service';

let api = undefined;

export default {
    name: "Favorite",
    components: {carousel},
    data() {
        return {
            profiles: []
        }
    },
    mounted() {
        api = new Api();
        api
            .getFavorites()
            .then((res) => {
                this.profiles = res.data.data;
            })
    },
    methods: {
        load() {

        },
        removeFavorite(id, key) {
            api
                .toggleFavorite(id)
                .then((res) => {
                    this.profiles.splice(key, 1);
                    this.user.profile.is_favorited = res.data.data.is_favorited;
                    if (res.data.data.is_favorited) {
                        this.user.profile.favorites += 1;
                    } else {
                        this.user.profile.favorites -= 1;
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>