import Form from "@/components/payment/Form.vue";

export default [
    {
        path: '/payment/:id',
        name: 'payment_form',
        component: Form,
        meta: {
            layout: 'short-layout',
            withLoader: true
        }
    }
];