<template>
  <component :is="layout">
    <router-view/>
<!--    {{env}}-->
    <FBPixel></FBPixel>
    <VKPixel></VKPixel>
<!--    <GooglePixel></GooglePixel>-->
  </component>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {mapGetters} from 'vuex';
import FBPixel from "@/components/widgets/FBPixel";
import VKPixel from "@/components/widgets/VKPixel";
import GooglePixel from "@/components/widgets/GooglePixel";

export default {
  name: 'app',
  components: {GooglePixel, VKPixel, FBPixel},
  computed: {
    ...mapGetters(
        {
          layout: 'layout/layout'
        })
  },


  data() {
    return {
      toastCount: 0,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    if(process.env.VUE_APP_GMT){
      this.$gtm.enable(!process.env.VUE_APP_METRIKA_DEBUG_MODE);
    }

    this.$root.$on('error', (message) => {
      this.$bvToast.toast(message, {
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        autoHideDelay: 3000,
        appendToast: true
      })
    }),
        this.$root.$on('successful', (message) => {
          this.$bvToast.toast(message, {
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 2000,
            appendToast: true
          })
        })
  },
  methods: {
    onResize() {
      this.$store.commit('layout/SET_WIDTH', window.innerWidth);
    }
  },
};
</script>


<style type="text/css">
@import "assets/css/styles.css";

</style>
