var render, staticRenderFns
import script from "./GooglePixel.vue?vue&type=script&lang=js&"
export * from "./GooglePixel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4499e86a",
  null
  
)

/* custom blocks */
import block0 from "./GooglePixel.vue?vue&type=custom&index=0&blockType=noscript&v-if=process.env.VUE_APP_METRIKA_DEBUG_MODE%20%3D%3D%3D%20'false'"
if (typeof block0 === 'function') block0(component)

export default component.exports