import Vuex from 'vuex'
import Vue from 'vue'
import User from "./user/user";
import List from "./list/list";
import Layout from "./layout/layout";
import Place from "./place";
import VueApp from '../main';
Vue.use(Vuex);


let store =  new Vuex.Store({
    modules: {
        user: User,
        layout: Layout,
        list: List,
        place:Place,
    }
});

store.subscribe((mutation,state)=>{
    if (mutation.type.startsWith("user/options")){
        localStorage.setItem('user.store.options', JSON.stringify(state.user.options));
        VueApp.$emit('update-options');
    }

    if (mutation.type.startsWith("user/place")){
        localStorage.setItem('user.store.place', JSON.stringify(state.user.place));
        VueApp.$emit('update-place', state.user.place);
    }
});
export default store;