<template>
  <b-modal id="modal-reserve-photographer" centered hide-header hide-footer>
    <div class="modal-header border-bottom">
      <p class="modal-title modal-brand modal-title-sm">Подтверждение заказа</p>
      <div class="close" @click="$bvModal.hide('modal-reserve-photographer')"></div>
    </div>
    <div class="form-group">
      <label class="muted">Дата</label>
      <datepicker
          class="vdp-datepicker-inline inverted"
          :disabledDates="calendarDisableDates(calendar)"
          v-model="order.start_date"
          :inline="true"
          :language="languages[$store.state.user.options.locale]"
          :fullMonthName="true"
          :highlighted="{}"
          :mondayFirst="true"
          placeholder="Выберите дату"
      ></datepicker>
    </div>
    <div v-if="order.start_date">
      <div class="form-group">
        <label class="muted">Время начала съемки (чч:мм)</label>
        <div class="form-control-count">
          <vue-timepicker :value="getTime()" @change="changeTime" format="HH:mm" class="time-picker-sm"
                          hide-clear-button></vue-timepicker>
        </div>
      </div>
      <label class="muted">Длительность съемки, чч:мм</label>
      <div class="form-row form-row-wide">
        <div class="col-6">
          <div class="form-group">
            <div class="form-control-count">
              <input type="text" class="form-control form-control-sm" :value="getDuration()" :disabled="true">
              <div class="plus" v-on:click="changeDuration(1)"></div>
              <div class="minus" v-on:click="changeDuration(-1)"></div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <div class="custom-control custom-checkbox custom-checkbox-block">
              <input type="checkbox" class="custom-control-input" id="all-day-checkbox-modal" maxlength="2"
                     v-model="order.is_full_day">
              <label class="custom-control-label" for="all-day-checkbox-modal">Весь день</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ $t('profile.settings') }}</label>
        <button class="btn btn-secondary btn-block" v-on:click="showAdditionalParams">
          {{ $t('profile.addition_params') }}
        </button>

        <b-modal id="modal-additional-params" centered hide-header hide-footer>
          <div class="modal-header border-bottom">
            <p class="modal-title modal-title-sm">Дополнительные параметры</p>
            <div class="close" @click="$bvModal.hide('modal-additional-params')"></div>
          </div>
          <div class="form-group">
            <label>Человек</label>
            <b-dropdown no-flip
                        :text="order.count_people ? $t('lists.people_count.' + order.count_people) : ''"
                        class="form-dropdown form-dropdown-md">
              <b-dd-text v-for="counters in $store.state.list.people_counter">
                <div class="dropdown-item" :class="{active:order.count_people === counters}"
                     v-on:click="order.count_people = counters"> {{ $t('lists.people_count.' + counters) }}
                </div>
              </b-dd-text>
            </b-dropdown>
          </div>
          <div class="form-group" v-if="order.equipment">
            <label>Оборудование</label>
            <b-dropdown no-flip :text="getServices(order.equipment, 'equipment')" class="form-dropdown">
              <b-dd-text v-bind:key="location" v-for="equipment in $store.state.list.equipment">
                <div class="dropdown-checkbox"
                     :class="{active:order.equipment.indexOf(equipment) > -1 }"
                     v-on:click="setData('equipment', equipment)">
                  {{ $t('lists.equipment.' + equipment) }}<br>
                </div>
              </b-dd-text>

            </b-dropdown>
          </div>
          <div class="form-group" v-if="orderData.additional_services">
            <label>Услуги</label>
            <b-dropdown no-flip :text="getServices(this.order.services, 'additional_services')" class="form-dropdown">
              <b-dd-text v-bind:key="additional_services" v-for="additional_services in orderData.additional_services">
                <div class="dropdown-checkbox"
                     :class="{active:order.services.indexOf(additional_services) > -1 }"
                     v-on:click="setData('services', additional_services)">
                  {{ $t('lists.additional_services.' + additional_services) }}<br>
                </div>
              </b-dd-text>

            </b-dropdown>
          </div>
          <div class="form-group">
            <label class="p-md weight-700">Локация съемки</label>
            <b-dropdown no-flip :text="getServices(order.locations, 'locations')" class="form-dropdown">
              <b-dd-text v-bind:key="location" v-for="location in orderData.locations">
                <div class="dropdown-checkbox"
                     :class="{active:order.locations.indexOf(location) > -1 }"
                     v-on:click="setData('locations', location)">
                  {{ $t('lists.locations.' + location) }}<br>
                </div>
              </b-dd-text>

            </b-dropdown>
          </div>
          <button class="btn btn-primary btn-block btn-lg" @click="$bvModal.hide('modal-additional-params')">Применить
          </button>
        </b-modal>
      </div>
      <div class="form-group form-total form-row align-items-center">
        <div class="w-100 form-group">
          <label class="muted">Промокод</label>
          <div class="input-group">
            <input v-model="order.promo_code" class="form-control form-control-sm form-control-uppercase">
            <div class="input-group-append">
              <span class="input-group-text pointer" v-on:click="getFullPrice()">Применить</span>
            </div>
          </div>
          <div v-if="promo_code_has_error" class="form-text text-danger">Ошибка: промокод не найден</div>
        </div>
        <div class="col">
          <label class="muted">Моя съемка: <br> {{ order.start_date | moment("DD MMMM YYYY в HH:mm") }} <br>Продолжительность:
            {{ getDuration() }}</label>
        </div>
        <div class="col-auto text-right d-flex flex-column">
          <div class="price old" v-if="price.discount_sum > 0">
            {{ price.full_price | number('0,0', {thousandsSeparator: ' '}) }} <span class="currency"><i
              class="rub">Р</i></span></div>
          <div class="price hot">{{ price.price | number('0,0', {thousandsSeparator: ' '}) }} <span class="currency"><i
              class="rub">Р</i></span></div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary btn-block" :disabled="!isReady || isPhotographer()" v-on:click="$emit('book')">
      {{ $t('profile.book') }}
    </button>
    <p v-if="isPhotographer()">Переключитесь на профиль клиента.</p>
    <div class="mt-3">
      <p class="muted small">30% оплачиваются при брони. Вернутся, если заказ не принят, или вы передумали не менее, чем
        за 24 часа до съемки.</p>
      <p class="muted small">Обсудить съемку с фотографом можно после бронирования.</p>
    </div>
  </b-modal>
</template>

<script>
import datepicker from "vuejs-datepicker";
import {BModal} from "bootstrap-vue";
import * as lang from "vuejs-datepicker/dist/locale";
import moment from 'moment';
import VueTimepicker from 'vue2-timepicker/src'
import ProfileApi from "../profile/service";

let api = new ProfileApi();
export default {
  name: "ShortOrder",
  components: {datepicker, BModal, VueTimepicker},
  props: {
    order: {
      type: Object
    },
    price: {
      type: Object,
      default() {
        return {
          price: 0,
          discount_sum: 0,
          full_price: 0
        }
      }
    },
    orderData: {
      type: Object
    },
    durations: {
      type: Array
    },
    user: {
      type: Object
    },
    calendar: {
      type: Object,
      default() {
        return {
          is_main: true,
          calendar: []
        }
      }
    }
  },
  computed: {
    isReady() {
      return this.order.start_date
          && this.order.equipment;
    },
  },
  data() {
    return {
      languages: lang,
      promo_code_has_error: false,
    }
  },
  methods: {
    getTime() {
      return {
        HH: moment(this.order.start_date).format('HH'),
        mm: moment(this.order.start_date).format('mm')
      }
    },
    changeTime(time) {
      this.order.start_date.setHours(time.data.HH);
      this.order.start_date.setMinutes(time.data.mm);
      this.$forceUpdate();
    },
    isHoursActive(time) {
      return moment(this.order.start_date).format('HH:mm') === time
    },
    addFavorite() {
      this.$emit('addFavorite');
    },
    showAdditionalParams() {
      this.$bvModal.show('modal-additional-params');
    },
    setType(type) {
      this.order.photosession_type = type.value
      this.$forceUpdate();
    },
    setData(type, value) {
      let index = this.order[type].indexOf(value);
      if (index === -1) {
        this.order[type].push(value)
      } else {
        this.order[type].splice(index, 1);
      }
      this.$forceUpdate();
    },
    getDuration() {
      return moment(this.order.duration).format('H:mm');
    },
    getFullPrice() {
      if (!this.loading && this.order.photosession_type) {
        this.loading = true;
        this.promo_code_has_error = false;
        api
            .getPrice(
                this.order.photographer.id,
                this.order.photosession_type,
                moment(this.order.duration).diff(moment().startOf('day'), 'minutes'),
                this.order.promo_code
            )
            .then((data) => {
              this.price = data.data.data;
              if (this.promo_code && this.price.discount_sum === 0) {
                this.promo_code_has_error = true;
              }
              this.loading = false;
            }).then((err) => {
          this.loading = false;
        })
      }

    },
    changeDuration(index) {
      this.changeDurationHelper(this.durationIndex, index, this.order);
      this.getFullPrice();
    },
  }
}
</script>

<style scoped>

</style>