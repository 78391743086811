<template>
    <div class="order-list">
        <order-progress :status="order.status" :is_canceled="order.is_canceled"></order-progress>
        <order-detail :order="order"></order-detail>
    </div>
</template>

<script>
import OrderProgress from "./OrderProgress";
import OrderDetail from "./OrderDetail";
export default {
    name: "OrderBlock",
    props:{
        order:{
            type:Object
        }
    },
    components: {OrderDetail, OrderProgress}
}
</script>

<style scoped>

</style>