<template>
    <div class="row">
        <div class="col-12 col-xl-4 d-none d-xl-block">
            <div class="account-sidebar">
                <div class="account-sidebar-block">
                    <p class="h2">Заказы</p>

                    <ul class="account-sidebar-nav">
                        <li>
                            <a href="#orders-2">Активные заявки</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.all.length}}</div>
                            </div>
                        </li>
                        <li>
                            <a href="#orders-3">Завершенные</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.finish.length}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="account-sidebar-block border-top">
                    <ul class="account-sidebar-nav">
                        <li>
                            <router-link :to="{name:'chats.area'}">Сообщения</router-link>
<!--                            <div class="label">-->
<!--                                <div class="count">4</div>-->
<!--                            </div>-->
                        </li>
<!--                        <li>-->
<!--                            <router-link  :to="{name:'order.area'}">Заявки</router-link>-->
<!--&lt;!&ndash;                            <div class="label">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="count">24</div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                        </li>-->
                    </ul>
                </div>
                <div class="account-sidebar-block">
                    <router-link :to="{name:'personal.area'}" class="btn btn-primary btn-text-medium btn-block">Перейти в профиль</router-link>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-8" >
            <div class="inner-content">
                <hr class="xl">
                <orders id="orders-collapse-hot" :orders="sortedOrders.all" title="Заявки" ></orders>
                <hr class="xl">
                <orders id="orders-collapse-finish" :orders="sortedOrders.finish" title="Завершенные заказы"></orders>
            </div>
        </div>
    </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import * as lang from "vuejs-datepicker/dist/locale";
import Api from '../../service';
import moment from 'moment'
import TimeCalendar from "../../../widgets/TimeCalendar";
import Orders from "../widget/order/Orders";

let api = undefined;
export default {
    name: "OrderClient",
    components: {Orders, TimeCalendar, datepicker},
    data() {
        return {
            languages: lang,
            orders: [],
            sortedOrders: {
                all:[],
                finish: []
            },

        }
    },
    methods: {
        loadOrders() {
            api
                .getOrders()
                .then((res) => {
                    this.orders = res.data.data;
                    this.sortingOrders(res.data.data);
                });
        },

        sortingOrders(orders) {
            this.sortedOrders =  {
             all:[],
                finish: []
            };
            orders.forEach((order) => {
                if(order.status.code === 'finish'){
                    this.sortedOrders.finish.push(order);
                }else{
                    this.sortedOrders.all.push(order);
                }

            });
        },

    },
    watch: {
    },
    mounted() {
        api = new Api();
        this.loadOrders();
        this.$root.$on('cancel_order',()=>{
          this.loadOrders();
        });
    }
}
</script>

<style>
/*.travels .vdp-datepicker__calendar .cell.selected{*/
/*    background: #FFFFFF !important;*/
/*}*/
</style>