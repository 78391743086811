<template>
  <div class="form-control-number-block">
    <div class="disable" v-if="disabled"></div>
    <p class="h2"> {{ name }}</p>
    <p class="form-text text-muted">{{ notice }} <strong v-if="notice_2" class="text-danger"><br>{{ notice_2 }}</strong></p>
    <div class="row">
      <div class="col-6 col-md" v-for="key in order">
        <div class="form-group">
          <label> {{ $t('lk.payment_area.time_lines.' + key) }}</label>
          <div class="form-control-number">
            <money class="form-control" v-model="values[key]" v-if="is_money"></money>
            <input type="number" v-else class="form-control" v-model="values[key]"/>
            <div class="plus" v-on:click="increment(values, key)"></div>
            <div class="minus" v-on:click="decrement(values, key)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Money} from 'v-money'

export default {
  name: "Matrix",
  components: {Money},
  data() {
    return {
      maxStep: '10000',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    level: {
      type: String,
      default: ""
    },
    notice: {
      type: String,
      default: ""
    },
    notice_2: {
      type: String,
      default: ""
    },
    order: {
      type: Array,
      default() {
        return [];
      }
    },
    values: {
      type: Object,
      default() {
        return {};
      }
    },
    is_money: {
      type: Boolean,
      default() {
        return true
      }
    },
    limits: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    values: {
      handler: function (newValue, oldVal) {
        for (let [key, value] of Object.entries(newValue)) {
          let limits = this.getLimits(key);
          if (limits) {
            if (limits[0] && limits[0] > value) {
              newValue[key] = limits[0];
              this.values[key] = limits[0];
            }
            if (limits[1] && limits[1] < value) {
              newValue[key] = limits[1];
              this.values[key] = limits[1];
            }
          }

          if (typeof value === 'string') {
            this.values[key] = parseInt(value);
          }
          if (value < 1) {
            this.values[key] = 1;
            newValue[key] = 1;
          }
          if (value === '') {
            this.values[key] = 1;
          }
        }
        // this.$forceUpdate();
      },
      deep: true
    }
  },
  methods: {
    getLimits(key) {
      if (this.limits.length === 0) {
        return false;
      }
      if (typeof this.limits[key] === undefined) {
        return false
      }

      return this.limits[key];
    },
    increment(values, key) {
      let value = values[key];
      let count = parseInt(value.toString().length - 1);
      if (count === 0) {
        count = 2;
      }
      let step = this.maxStep.substring(0, count);
      if (this.limit > 0 && this.limit <= parseInt(values[key]) + parseInt(step)) {
        return;
      }
      values[key] = parseInt(values[key]) + parseInt(step);
    },
    decrement(values, key) {
      let value = values[key];
      let count = parseInt(value.toString().length - 1);
      if (count === 0) {
        count = 1;
      }
      let step = this.maxStep.substring(0, count);
      values[key] = values[key] - parseInt(step) < 0 ? 0 : values[key] - parseInt(step);
    }
  }
}
</script>

<style scoped>
.form-control-number-block {
  position: relative;
}

.disable {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.6;
  z-index: 999;
}
</style>