<template>
  <div>
    <div class="photo-upload-container">
      <vue2-dropzone
          class="photo-upload"
          :ref="reference"
          id="dropzone"
          @vdropzone-file-added="start"
          @vdropzone-success="end"
          :useCustomSlot="true"
          :options="options">
        <div class="dropzone-custom-content content">
          <p class="title">{{ $t('lk.photos_area.upload.title') }}</p>
          <p class="comment">{{ $t('lk.photos_area.upload.comment') }}</p>

        </div>
      </vue2-dropzone>
      <div v-if="isLoading" class="loader">
        <div class="spinner-border text-primary"></div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "Uploader",
  data() {
    return {
      photos: [],
      interval: false,
      isLoading: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg, image/jpg, image/png'
      }
    }
  },
  props: {
    url: {
      type: String,
      default: ""
    },
    reference: {
      type: String,
      default: "dropZone"
    }
  },
  computed: {
    options: function () {
      return {
        url: this.$http.options.root + this.url,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        maxFiles: 5,
        paramName: 'image',
        headers: {"Authorization": "Bearer " + this.$auth.token()}
      }
    }
  },
  watch: {},
  components: {vue2Dropzone},
  methods: {
    start() {
      this.isLoading = true;
    },
    end() {
      this.isLoading = false;
    },
  }
}
</script>

<style>
.dz-processing {
  display: none !important;
}

.dz-complete {
  display: none !important;
}
</style>