export default {
    namespaced: true,

    state: {
        city: {
            id: 'ChIJybDUc_xKtUYRTM9XV8zWRD0',
            ru: 'Москва',
            en: 'Moscow'
        },
        raw: {},
    },
    mutations: {},
    actions: {},
    getters: {}
}