import Vue from "vue";
import Role from "./role";

Vue.mixin({
    methods: {
        getName(user){
            return user.name;
        },
        shuffle(array) {
            array.sort(() => Math.random() - 0.5);
            return array;
        }
    }
})