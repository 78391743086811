import PersonalArea from './PersonalArea'
import Personal from './components/page/Personal'
import Payment from './components/page/Payment'
import Photos from './components/page/Photos'
import Profile from './components/page/Profile'
import Secure from './components/page/Secure'
import Notification from './components/page/Notification'
import Order from './components/page/Order'
import Favorite from './components/page/Favorite'
import Role from '../../_helpers/role'

export default [
    {
        path: '/personal_area',
        component: PersonalArea,
        meta: {
            layout: 'short-layout',
            auth: [Role.Photographer, Role.Client],
        },
        children: [
            {
                path: '',
                name: 'personal.area',
                component: Personal,
                meta: {
                    layout: 'short-layout',
                    withLoader: true
                },
            },
            {
                path: 'payment',
                name: 'payment.area',
                component: Payment,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                    // withLoader: true
                },
            },
            {
                path: 'photos',
                name: 'photos.area',
                component: Photos,
                meta: {
                    layout: 'short-layout',
                    withLoader: true
                },
            },
            {
                path: 'profile',
                name: 'profile.area',
                component: Profile,
                meta: {
                    layout: 'short-layout',
                    withLoader: true
                },
            },
            {
                path: 'secure',
                name: 'secure.area',
                component: Secure,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                },
            },
            {
                path: 'notification',
                name: 'notification.area',
                component: Notification,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                },
            },
            {
                path: 'order',
                name: 'order.area',
                method:['POST','GET'],
                component: Order,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true,
                    hideSideBar: true,
                },
            },
            {
                path: 'favorite',
                name: 'favorite.area',
                component: Favorite,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                },
            }
        ]
    }
]