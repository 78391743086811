<template>
  <div class="navbar-location d-none d-md-flex">
    <span v-b-modal.modal-choose-city v-if="$store.state.user.options.city">{{ $store.state.user.options.city[$store.state.user.options.locale] }}</span>
    <cities-popup ref="cities" :selected-city="$store.state.user.options.city" v-if="$store.state.user.options.city"></cities-popup>

    <div class="confirm-city" v-if="city && show_geo">
      <div>Ваш город</div>
      <div class="city">{{ city[$store.state.user.options.locale] }}</div>
      <div class="row">
        <div class="col-6">
          <button class="btn btn-sm btn-block btn-primary" v-on:click="show_geo = false">Да</button>
        </div>
        <div class="col-6">
          <button class="btn btn-sm btn-block btn-bordered" v-on:click="close()">Нет</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitiesPopup from "../components/widgets/CitiesPopup";
import Api from "../components/services";
import axios from "axios";

let api = undefined;
export default {
  name: "City",
  components: {CitiesPopup},
  data() {
    return {
      city: undefined,
      show_geo: false
    }
  },
  mounted() {
    api = new Api();

    if (!this.$store.state.user.options.city) {
      this.detectFirst();
    } else {
      this.event();
    }
  },
  methods: {
    detectFirst() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((data) => {
          this.getCity(data.coords.latitude, data.coords.longitude);
        }, (er) => {
          this.detectSecond();
        }, {
          enableHighAccuracy: true
          , timeout: 500
        })
      } else {
        this.detectSecond();
      }
    },
    detectSecond() {
      let url = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + process.env.VUE_APP_GOOGLE_API_KEY;
      const options = {
        method: 'POST',
        headers: {'content-type': 'text/plain'},
        data: '',
        url,
      };
      axios(options).then((data) => {
        this.getCity(data.data.location.lat, data.data.location.lng);
      });

    },
    getCity(lat, lng) {
      api.geoIp({lat: lat, lng: lng}).then((data) => {
        this.show_geo = true;
        this.city = data.data.data;
        this.$store.commit('user/setCity', this.city)
        setTimeout(() => {
          this.event()
        }, 100);
      })
    },

    close() {
      this.show_geo = false;
      this.$bvModal.show('modal-choose-city');
    },
    event() {
      this.$refs.cities.$on('add-city', (city) => {
        this.$store.commit('user/setCity', city)
      })
    }
  }

}
</script>

<style scoped>

</style>