import axios from 'axios';
import store from '../store/main'


export default class Api {
    constructor() {
        const token = store.state.user.token;
        this.web = axios.create({
            baseURL: process.env.VUE_APP_SERVER_API,
            headers: {
                // Authorization: token ? `Bearer ${token}` : '',
                'X-Auth-Token': token ? `Bearer ${token}` : '',
            },
        });
        this.web.interceptors.request.use(
            config => {
                const token = store.state.user.token;
                if (token) {
                    config.headers['X-Auth-Token'] = 'Bearer ' + token;
                }
                // config.headers['Content-Type'] = 'application/json';
                return config;
            },
            error => {
                Promise.reject(error)
            });


    }

    register(user) {
        return this.web.post('/auth/register', user);
    }

    recovery(email) {
        return this.web.post('/password/forgot', {email: email});
    }

    reset(obj) {
        return this.web.post('/password/reset', obj);
    }

    verification(code) {
        return this.web.get('/auth/check-confirm/' + code);
    }

    cities() {
        if (store.state.list.cities.length === 0) {
            this.web
                .get('/geo/cities/top')
                .then((res) => {
                    store.state.list.cities = res.data.data;
                });
        }

    }
    lists() {
        return this.web
            .get('/list/all')
            .then((res) => {
                store.state.list.categories = [];
                store.state.list.locations = res.data.data.photoLocationType;
                store.state.list.equipment = res.data.data.equipment;
                store.state.list.languages = res.data.data.languages;
                store.state.list.photo_readiness = res.data.data.photoReadiness;
                store.state.list.sessions = res.data.data.photoSessionType;
                store.state.list.additional_services = res.data.data.photoAdditionalService;
                store.state.list.people_counter = res.data.data.numberPeople;
                res.data.data.categories.forEach(element => element.active ? store.state.list.categories.push(element.value) : '')
            });
    }


    toggleFavorite(id) {
        return this.web.patch('/personal/favorite', {profile_id: id});
    }

    searchTown(object) {
        const params = new URLSearchParams();

        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                params.append(key, object[key])
            }
        }
        return this.web.get('/search-town', {
            params: params
        });
    }

    geoIp(data){
        const params = new URLSearchParams();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                params.append(key, data[key])
            }
        }

        return this.web.get('/geo', {
            params: params
        });
    }
    geoAddress(data){
        const params = new URLSearchParams();

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                params.append(key, data[key])
            }
        }

        return this.web.get('/geoAddress', {
            params: params
        });
    }
    saveCoordinates(lat,lng){
        return this.web.post('/personal/profile/coordinates', {lat:lat,long:lng});
    }
    getNewConfirmCode(key){
        return this.web.get('/auth/resend-code?key='+key);
    }
    confirmPhone(code, key){
        return this.web.get('/auth/check-code?code='+code+'&key='+key);
    }
    sendFeedback(feedback){
        return this.web.post('/feedback', {name:feedback.name,email:feedback.email, message:feedback.message});
    }
    // geoIp(){
    //     return this.web.post('https://www.googleapis.com/geolocation/v1/geolocate?key='+process.env.VUE_APP_GOOGLE_API_KEY);
    // }
}