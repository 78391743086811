// import Role from '../../_helpers/role'
import Search from "./Search";

export default [
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            layout: 'short-layout',
            hide_footer: true,
        },
        children: [
            {
                path: 'live',
                name: 'live_search',
                component: Search,
                meta: {
                    layout: 'short-layout',
                    hide_footer: true,
                },
            }
        ]
    }
]