import PersonalArea from '../lk/PersonalArea'
import ChatList from './ChatList'
import Chat from './Chat'
import Role from '../../_helpers/role'

export default [
    {
        path: '/personal_area',
        component: PersonalArea,
        meta: {
            layout: 'short-layout',
            auth: [Role.Photographer, Role.Client],
        },
        children: [
            {
                path: 'chats',
                name: 'chats.area',
                component: ChatList,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                },
            },
            {
                path: 'chat/:id',
                name: 'chat.area',
                component: Chat,
                meta: {
                    layout: 'short-layout',
                    emulateLoader: true
                },
            }
        ]
    }
]