<template>
    <div id="error404">
        <img src="/assets/images/404.svg" width="326" height="285" class="img-fluid">
        <p class="title">Эта страница не найдена.</p>
        <p><router-link :to="{name:'main_page'}" class="btn btn-bordered">На гавную</router-link></p>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>