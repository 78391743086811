<template>
    <b-dropdown variant="" toggle-tag="div" right no-flip toggle-class="navbar-notifications" menu-class="navbar-notifications-dropdown" no-caret>
        <template v-slot:button-content>
            <div class="pointer">
                <span class="new"></span>
            </div>
        </template>
        <b-dropdown-item  class="notifications-nav-item" :to="{name:'order.area'}">
            <div class="title">Заказы</div>
            <div class="new">Новый заказ</div>
            <div class="label">{{orders.length}}</div>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :to="{name:'chats.area'}" class="notifications-nav-item">
            <div class="title">Сообщения</div>
            <div class="label">0</div>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>

        <b-dropdown-group :header="date | moment('calendar')" v-for="date in dates" >
            <b-dropdown-item :to="getLink(notify.type)" class="notification-item" v-for="notify in filterNotify(date)">
                <div class="notification-header">
                    <div class="title">{{notify.data.clientName}}</div>
                </div>
                <div class="notification-body">{{notify.data.message}}</div>
            </b-dropdown-item>
        </b-dropdown-group>
    </b-dropdown>
</template>

<script>
import NotifyApi from "./services";
import moment from 'moment';

let api = new NotifyApi();
export default {
    name: "Notify",
    data() {
        return {
            limit:5,
            show:0,
            dates: [],
            notifications: [],
            orders: [],
            last_update: moment(),
        }
    },
    mounted() {
        this.getNotify();
        this.getOrders();
        setInterval(() => {
            this.update();
            this.getOrders();
        }, 60000);//TODO долбим сервак херовая идея но да что поделать пока прототип
    },
    methods: {
        getLink(type){
          let orders = [
                  'OrderStatus',
                  'OrderRequest',
          ]
          if(orders.indexOf(type) > -1 ){
              return  {name:'order.area'};
          }
          return {name:'chat.area'};
        },
        getOrders() {
            api.getOrders().then((res) => {
                this.orders = res.data.data;
            })
        },
        getNotify() {
            let self = this;
            api.getNotify().then((res) => {
                self.dates = [];
                self.notifications = [];
                let notification =  res.data.data.slice(0, res.data.data.length > this.limit ? this.limit : res.data.data.length);
                notification.forEach((notify) => {
                    if (self.dates.indexOf(moment(notify.created_at).format('YYYY-MM-DD')) === -1) {
                        self.dates.push(moment(notify.created_at).format('YYYY-MM-DD'));
                    }
                    self.notifications.push(notify);
                    self.last_update = moment();
                })
            })
        },
        update() {
            let self = this;
            api.getNewNotify( this.last_update.format('DD.MM.YYYY HH:mm:ss')).then((res) => {
                res.data.data.forEach((notify) => {
                    if (self.dates.indexOf(moment(notify.created_at).format('YYYY-MM-DD')) === -1) {
                        self.dates.push(moment(notify.created_at).format('YYYY-MM-DD'));
                    }
                    self.notifications.push(notify);
                    self.last_update = moment();
                })
            })
        },
        filterNotify(date) {
            return this.notifications.filter(not =>
                moment(not.created_at).format('YYYY-MM-DD') === date
            )
        }
    }
}
</script>

<style scoped>

</style>