<template>
    <PhotographerPayment v-if="isPhotographer()"></PhotographerPayment>
    <ClientPayment v-else></ClientPayment>
</template>

<script>
import PhotographerPayment from "../widget/PhotographerPayment";
import ClientPayment from "../widget/ClientPayment";


export default {
    name: "Payment",
    components: {ClientPayment, PhotographerPayment},
    data(){
      return{

      }
    },
    mounted() {

    },
    methods:{

    }
}
</script>

<style scoped>

</style>