<template>
    <b-modal id="modal-reset-password" centered hide-header :hide-footer="!responseError"  @hide="close">
        <div class="modal-header">
            <p class="modal-title modal-brand">{{ state === states.reset ? 'Введите новый пароль' : 'Ваш пароль измененен успешно!'}}</p>
            <div class="close" @click="$bvModal.hide('modal-reset-password')"></div>
        </div>
        <div class="d-block text-center" v-if="state === states.reset">
            <p>Не вводите простой пароль!</p>
            <div class="form-group">
                <input type="password" v-model="request.password" class="form-control" placeholder="Новый пароль">
                <div class="show-password pointer"></div>
            </div>
            <div class="form-group">
                <input type="password" class="form-control" v-model="request.password_confirmation" placeholder="Повторите пароль">
                <div class="show-password pointer"></div>
            </div>
            <div class="modal-links">
                <b-button variant="primary" size="lg" class="btn-uppercase" block v-on:click="reset">Сохранить новый пароль</b-button>
            </div>
        </div>
        <div class="d-block text-center" v-else>
            <p>Войдите под вашим аккаунтом</p>
            <div class="modal-links">
                <b-button variant="primary" size="lg" class="btn-uppercase" block v-on:click="close">Войти</b-button>
            </div>
        </div>
        <template v-slot:modal-footer v-if="responseError">
            <b-toast title="" static no-auto-hide :visible="true" variant="danger">
                {{responseError}}
            </b-toast>
        </template>
    </b-modal>
</template>

<script>
import Api from '../services';
const
    STATE_RESET = 'reset',
    STATE_RESETED = 'reseted';
let api;
export default {
    name: "ResetPassword",
    data() {
        return {
            state: STATE_RESET,
            states: {
                reset: STATE_RESET,
                reseted: STATE_RESETED,
            },
            responseError: false,
            request:{
                email:this.$route.query.email,
                token:this.$route.query.token,
                password:'',
                password_confirmation:'',
            }
        }
    },
    mounted() {
       api = new Api();

        if(this.request.email && this.request.token){
            this.$bvModal.show('modal-reset-password')
        }

    },
    methods: {
        close(){//TODO повесить ивент на закрытие попапа
            this.$router.push('/');
            this.$bvModal.hide('modal-reset-password');
            this.$bvModal.show('modal-login');
            this.$root.$emit('reload-main');
        },
        reset() {
            this.responseError = false;
            if (this.request.password_confirmation === this.request.password) {
                api
                    .reset(this.request)
                    .then(
                        (res) => {
                            this.$root.$emit('successful', this.$t('auth.resend_password'));
                            this.state = STATE_RESETED;
                        }
                    )
                    .catch((error) => {
                        this.$root.$emit('successful', this.$t('auth.resend_password'));
                        // this.responseError = error.response.data.message;
                    });
            } else {
                this.responseError = 'Пароли не совпадают';
            }
        }
    }
}
</script>

<style scoped>

</style>