<template>
    <div class="d-flex align-items-center">
        <notify></notify>
        <b-dropdown variant="" toggle-tag="div" right no-flip toggle-class="navbar-profile" menu-class="navbar-profile-dropdown" no-caret>
            <template v-slot:button-content>
                <div
                        class="avatar"
                        :style="{backgroundImage: `url(${avatar['thumb-56']})`, }">
                </div>
            </template>
            <b-dropdown-item :to="{name:'personal.area'}" class="dropdown-header">Профиль</b-dropdown-item>
            <b-dropdown-item :to="{name:'personal.area'}">Персональные данные</b-dropdown-item>
            <b-dropdown-item :to="{name:'payment.area'}" >{{isPhotographer() ? 'Настройка выплаты' : 'Настройка оплат'}}</b-dropdown-item>
<!--            <b-dropdown-item href="#">Транзакции</b-dropdown-item>-->
            <b-dropdown-item :to="{name:'profile.area'}">Данные профиля</b-dropdown-item>
            <b-dropdown-item :to="{name:'secure.area'}">Безопасность</b-dropdown-item>
            <b-dropdown-item :to="{name:'notification.area'}">Оповещения</b-dropdown-item>
            <b-dropdown-item :to="{name:'photos.area'}" v-if="isPhotographer()">Фотографии</b-dropdown-item>
            <b-dropdown-item :to="{name:'favorite.area'}">Избранное</b-dropdown-item>
            <b-dropdown-item class="dropdown-header">Поддержка</b-dropdown-item>
            <b-dropdown-item class="dropdown-header">FAQ</b-dropdown-item>
            <b-dropdown-item :to="{name:'photographer_profile', params:{id:$auth.user().id}}" class="dropdown-header" v-if="isPhotographer()">Моя страница</b-dropdown-item>
            <!--b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Заказы</b-dropdown-header>
            <b-dropdown-item :to="{name:'order.area'}">Заказы</b-dropdown-item-->
            <b-dropdown-form>
                <b-button variant="primary" block v-on:click="logout">Выйти</b-button>
            </b-dropdown-form>
        </b-dropdown>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {BDropdown} from 'bootstrap-vue';
import Notify from "../notifications/Notify";

export default {
    name: "AuthUser",
    components: {
        Notify,
        BDropdown
    },
    computed: {
        ...mapGetters({
            avatar: 'user/avatar'
        })
    },
    methods: {
        logout() {
            this.$auth.logout();
            this.$store.commit('user/logout');
        }
    },
}
</script>

<style scoped>

</style>