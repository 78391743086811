<template>
  <div>
    <confirm-phone ref="confirmPhone"></confirm-phone>

    <b-modal id="modal-update-phone" centered hide-header hide-footer>
      <div class="modal-header">
        <p class="modal-title modal-title-sm">Подтверждение телефона</p>
        <div class="close" @click="hide"></div>
      </div>
      <div class="form-group">
        <label>Номер телефона:</label>
        <vue-tel-input ref="tel" wrapperClasses="form-control-phone" inputClasses="form-control form-control"
                       v-model="phone" :valid-characters-only="true" :dynamicPlaceholder="true"></vue-tel-input>
      </div>

      <button type="submit" class="btn btn-primary btn-block" v-on:click="save()">Ок</button>
    </b-modal>
  </div>

</template>

<script>
import Api from "@/components/lk/service";
import ConfirmPhone from "@/components/lk/components/widget/ConfirmPhone";

let api = undefined;
export default {
  name: "ChangePhone",
  components: {ConfirmPhone},
  data() {
    return {
      phone: '',
    }
  },
  mounted() {
    api = new Api();
    this.phone = this.$auth.user().phone;
  },
  methods: {
    hide() {
      this.$bvModal.hide('modal-update-phone');
    },
    save() {
      if ((this.phone !== '' && this.$refs.tel.phoneObject.isValid)) {
        api
            .updatePersonal({email: this.$auth.user().email, phone: this.phone})
            .then((res) => {
              this.hide();
              if (res.data.data.phone_confirmation && res.data.data.phone_confirmation.is_confirm === false) {

                this.$refs.confirmPhone.show(
                    {
                      timer: res.data.data.phone_confirmation.resend_life_time
                    }
                );
              }
            })
            .catch((error) => {
              this.$root.$emit('error', error.response.data.message);
            })
      }

    }
  }

}
</script>

<style scoped>

</style>