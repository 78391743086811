<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control form-control-sm" placeholder="Ваше имя" v-model="feedback.name">
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control form-control-sm" placeholder="Ваш Email" v-model="feedback.email">
        </div>
      </div>
    </div>
    <div class="form-group">
      <textarea class="form-control form-control-xl" placeholder="Ваш вопрос" v-model="feedback.message"></textarea>
    </div>
    <button class="btn btn-primary btn-md" v-on:click="send" :disabled="is_disabled">Отправить</button>
  </div>
</template>

<script>

import Api from "@/components/services";

var api = undefined;
export default {
  name: "FeedBackForm",
  data() {
    return {
      is_disabled: false,
      feedback: {
        email: '',
        name: '',
        message: ''
      }
    }
  },
  mounted() {
    api = new Api();
  },
  methods: {
    send() {
      if (!this.feedback.email || !this.feedback.name || !this.feedback.message) {
        this.$root.$emit('error', 'Все поля обязательны!');
      }
      api
          .sendFeedback(this.feedback)
          .then(() => {
            this.is_disabled = true;
            this.$root.$emit('successful', 'Спасибо! Мы уже получили ваше сообщение и скоро вам ответим.');
          })
    }
  }
}
</script>

<style scoped>

</style>