<template>
    <div class="main-content flex-shrink-0">
        <div class="container">
        Авторизация
        </div>
    </div>
</template>

<script>
    export default {
        name: "OAuth",
        data() {
            return {
                access_token: this.$route.query.access_token,
                code: this.$route.query.code,
                type: this.$route.params.type
            }
        },
        mounted() {

            //редирект с соцсети
            if (this.access_token && this.type) {
                let state = JSON.parse(this.$route.query.state);
                this.$auth.oauth2({
                    code: true,
                    provider: this.type,
                    redirect: state.redirect_to,
                    params: {
                        access_token: this.access_token,
                    },
                    success: function(res) {
                    },
                    error: function (res) {
                        alert(res.data.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>