import Vue from 'vue'
import Router from 'vue-router'
import Http from 'vue-resource'
import store from './store/main'
import MainPage from './components/main/MainPage'
import Rule from './components/other/Rule'
import About from './components/other/About'
import LK from './components/lk/route'
import Search from './components/search/route'
import Profile from './components/profile/route'
import Chat from './components/chat/route'
import NotFound from './components/NotFound'
import Photographer from "@/components/other/Photographer";
import Form from "@/components/payment/route";


Vue.use(Http);
Vue.use(Router);
Vue.http.options.root = process.env.VUE_APP_SERVER_API;


let routes = [
    {
        path: '/',
        name: 'main_page',
        component: MainPage,
        meta: {
            layout: 'main-layout',
            withLoader: true
        }
    },
    {
        path: '/login',
        redirect: '/'
    },
    {
        path: '/rules',
        name:'rules',
        component: Rule,
        meta: {
            layout: 'short-layout',
            withLoader: false
        }
    },
    {
        path: '/about',
        name:'about',
        component: About,
        meta: {
            layout: 'short-layout',
            withLoader: false
        }
    },
    {
        path: '/for_photographers',
        name:'for_photographers',
        component: Photographer,
        meta: {
            layout: 'short-layout',
            withLoader: false
        }
    },
    {
        path: '/login/:type',
        name: 'oauth',
        component: require('@/components/auth/OAuth.vue').default,
        meta: {
            layout: 'short-layout'
        }
    },
    {
        path: '/login/verification/:code',
        name: 'verification',
        component: require('@/components/auth/Verification.vue').default,
        meta: {
            layout: 'short-layout'
        }
    },
    {
        path: '/reset',
        name: 'reset_password',
        // component: require('@/components/auth/Verification.vue').default,
        component: MainPage,
        meta: {
            layout: 'main-layout',
            withLoader: true
        }
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
        meta: {
            layout: 'short-layout',
            hide_footer:true,
        }
    },
    {
        path: '*',
        redirect: '/404'
    }

];
// });
routes = routes.concat(LK, Profile, Search, Chat, Form);

const router = new Router({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: 'active',
    // static: true,
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.hash && to.name === 'oauth') {
        router.push(to.fullPath.replace('#', '?'));
    }
    next();
});

router.afterEach((to) => {
    let layout = to.meta.layout || 'main-layout';
    store.commit('layout/set', layout);
    //TODO на пределку на эмит
    store.commit('layout/menu');

});

Vue.router = router;

export default router


