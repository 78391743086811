<template>
  <div class="inner-content">
    <p class="h2">{{ $t('lk.photos_area.photos_header') }}</p>
    <p>{{ $t('lk.photos_area.photos_lead') }}</p>
    <div class="photos-uploader">
      <div class="row">
        <div class="col-12 col-md-4" v-if="headers.length <= 20">
          <uploader ref="uploader" reference="headerZone" url="/personal/profile/header"></uploader>
        </div>
        <div class="col-12 col-md-4" v-for="(header, key) in headers">
          <div v-if="!header.isLoading"
              class="photo"
               :style="{backgroundImage: `url(${header.path.small_preview ? header.path.small_preview : header.path.medium_preview})`,}">
            <div class="hover">
              <div class="delete-photo" v-on:click="remove(header, key)">{{ $t('lk.photos_area.delete') }}</div>
            </div>
          </div>
          <div v-if="header.isLoading" class="photo loader">
            <div class="spinner-border text-primary"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="account-separator"></div>
    <album v-bind:key="album.id" :album="album" :number="key" v-for="(album, key) in albums"></album>
    <div class="account-separator line"></div>
    <div v-on:click="newAlbum" class="btn btn-primary btn-block btn-text-light btn-uppercase">
      {{ $t('lk.photos_area.add_album') }}
    </div>
  </div>
</template>

<script>

import Api from '../../service'
import Album from "../widget/Album";
import Uploader from "../widget/Uploader";

let api = undefined;

export default {
  name: "Photos",
  data() {
    return {
      limits: {
        albums: 10,
        photos: 20
      },
      albums: [],
      album: {
        id: null,
        name: '',
        categories: [],
        images: []
      },
      headers: [],
      loadModules: {
        headers: false,
        albums: false,
      }
    }
  },
  watch: {
    loadModules: {
      handler: function (newValue) {
        this.$root.$emit('load-modules', newValue);
      },
      deep: true
    }
  },
  components: {Uploader, Album},
  mounted() {
    api = new Api();
    api.lists();
    let self = this;
    this.load();
    let upload = this.$refs.uploader.$refs.headerZone.dropzone;
    let counter = 1;
    upload.on("success", function (file, res) {
      res.data.isLoading = true;
      self.headers.push(res.data);
      counter++;
      setTimeout(()=>{
        self.headers[self.headers.indexOf(res.data)].isLoading = false;
      }, 2000 + (counter * 2000));

    });
    this.$root.$on('removeAlbum', (albumEvent) => {
      this.albums.splice(this.albums.findIndex(album => album.id === albumEvent.id), 1);
    })
    //
  },
  methods: {
    newAlbum() {
      if (this.albums.filter(album => album.images.length === 0).length >= 1) {
        return;
      }
      if (this.albums.length >= this.limits.albums) {
        return;
      }
      if (this.albums.filter(album => album.id === undefined).length < 1) {
        this.albums.push(JSON.parse(JSON.stringify(this.album)))
      }
    },
    remove(image, key) {
      api
          .removeHeader(image.id)
          .then((res) => {
            this.headers.splice(key, 1)
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
          })
    },
    load() {
      api
          .getHeaders()
          .then((res) => {
            this.headers = res.data.data;
            this.loadModules.headers = true;
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
            this.loadModules.headers = true;
          });
      api
          .getAlbums()
          .then((res) => {
            this.albums = res.data.data;
            this.loadModules.albums = true;
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
            this.loadModules.albums = true;
          })

    }
  }
}
</script>

<style scoped>

</style>