<template>
    <div class="order-status scroll-horisontal">
        <div class="scroll-horisontal-inner">
            <ul class="no-list">
                <li :class="{complete:isComplete(status), waiting:isWaiting(status), canceled:isCanceled(status)}" v-for="status in statuses">
                    <div class="icon icon-wait" v-if="isWaiting(status) && !isCanceled(status)"></div>
                    {{ $t('lists.statuses.' + (isCanceled(status) ? 'canceled' : status)) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderProgress",
    props: {
        status: {
            type: Object
        },
        is_canceled: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            statuses: [
                'start',
                // 'confirmation',
                'payment',
                'confirmation',
                'processing',
                'issuing',
                'finish'
            ]
        }
    },
    methods: {
        getStatusIndex() {
            return this.statuses.indexOf(this.status.code)
        },
        isWaiting(status) {
            return this.getStatusIndex() + 1 === this.statuses.indexOf(status);
            // return false;
        },
        isComplete(status) {
            return this.getStatusIndex() >= this.statuses.indexOf(status);
        },
        isCanceled(status) {
            return this.getStatusIndex() + 1 === this.statuses.indexOf(status) && this.is_canceled;
        }
    }

}
</script>

<style scoped>

</style>