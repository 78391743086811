<template>
    <div class="inner-content">


        <p class="h2">{{$t('lk.payment_area.payments')}}</p>
        <p class="weight-700">{{$t('lk.payment_area.payments_list')}}</p>

        <div class="transaction-list">
<!--            <div class="row">-->
<!--                <div class="col-12 col-md-3 transaction-list-line">-->
<!--                    <div class="transaction-list-label">Номер заказа</div>-->
<!--                    <div class="transaction-list-value">0449943</div>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 transaction-list-line">-->
<!--                    <div class="transaction-list-label">Дата</div>-->
<!--                    <div class="transaction-list-value">12.03.2019</div>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-3 transaction-list-line transaction-list-price">-->
<!--                    <div class="transaction-list-label">Сумма</div>-->
<!--                    <div class="transaction-list-value">3200.00</div>-->
<!--                </div>-->
<!--            </div>-->
        </div>


<!--        <button class="btn btn-secondary btn-block btn-uppercase">Показать больше</button>-->

    </div>
</template>

<script>
import Matrix from "./Matrix";
import Api from '../../service';
import {TheMask} from 'vue-the-mask'
import Card from "./Card";

let api = undefined;

export default {
    name: "ClientPayment",
    components: {Card},
    data(){
      return{
          newCard:{
              expirationDate: "",
              name: "",
              number: "",
              type: ""
          },
          cards:[],
          roles:[],
          order:[],
          payments:{

          }
      }
    },
    mounted() {
        api = new Api();
        this.load();

    },
    methods:{

        load(){
            api
                .getCard()
                .then((res)=>{
                    this.cards = res.data.data.payout;
                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                });

        },
        save(){
            // this.saveCards();
            this.savePayments();
        },
        saveCards(){
            api
                .updateCard(this.cards)
                .then((res)=>{
                    this.$root.$emit('successful', 'Карты сохарнены');
                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                })
        },

    }
}
</script>

<style scoped>

</style>