<template>
    <div class="order-map">

        <GmapMap
                :center="marker"
                :zoom="10"
                :options="{
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false
			}"
        >
            <GmapMarker
                    :position="marker"
                    :clickable="false"
                    :icon="icon"
            />
        </GmapMap>
    </div>
</template>

<script>
export default {
    name: "OrderMap",
    props:{
        marker: {
            lat: 55.75, lng: 37.62
        },
    },
    data() {
        return {
            icon: {
                url: "/assets/images/map-marker.png",
                scaledSize: {width: 25, height: 31},
                origin: {x: 0, y: 0},
                anchor: {x: 10, y: 27}
            }
        }
    }
}
</script>

<style scoped>

</style>