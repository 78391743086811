var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.withSelect)?_c('b-form-tags',{attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control form-control-sm",attrs:{"placeholder":_vm.placeholder}},'b-form-input',inputAttrs,false),inputHandlers)),_c('p',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.subText))]),_c('ul',{staticClass:"tags-list no-list"},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,attrs:{"tag":"li","title":tag},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag)+" ")])}),1)]}}],null,false,2927608443),model:{value:(_vm.value[_vm.target]),callback:function ($$v) {_vm.$set(_vm.value, _vm.target, $$v)},expression:"value[target]"}}):_c('b-form-group',[_c('b-dropdown',{staticClass:"form-dropdown form-dropdown-sm form-dropdown-checkbox-block",attrs:{"no-flip":"","text":_vm.getText ? _vm.getText : _vm.placeholder}},_vm._l((_vm.selectList.filter(function (list_option) { return list_option.active === true || list_option.active === undefined; })),function(option){return _c('b-dd-text',{key:option},[_c('div',{staticClass:"dropdown-checkbox",class:{active:_vm.value[_vm.target].indexOf(option) !== -1,disable: !_vm.value[_vm.target].indexOf(option) && _vm.value[_vm.target].length >= _vm.limit},on:{"click":function($event){return _vm.toggleTag(option)}}},[_vm._v(_vm._s(_vm.$t('lists.'+_vm.target+'.'+option))+" ")])])}),1),_c('p',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.subText))]),_c('ul',{staticClass:"tags-list no-list"},_vm._l((_vm.value[_vm.target]),function(tag){return _c('li',{key:tag,attrs:{"title":tag}},[_vm._v(_vm._s(_vm.$t('lists.'+_vm.target+'.'+tag))+" "),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.removeTag(tag)}}})])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }