// import axios from 'axios';
import Api from '../services';

export default class ChatAreaApi extends Api {
    getChats(){
        return this.web.get('/chat');
    }
    getChat(id){
        return this.web.get('/chat/'+id);
    }
    getNewMessages(id, date){
        const params = new URLSearchParams();
        params.append('created_at', date)
        return this.web.get('/chat/'+id, {
            params: params
        });
    }
    createNewMessage(order_id, message){
        return this.web.post('/chat/'+order_id, {message:message});
    }
}