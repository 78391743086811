<template>
    <div>
        <PhotographerArea v-if="isPhotographer()"></PhotographerArea>
        <UserArea v-else></UserArea>
    </div>
</template>

<script>
    import PhotographerArea from "./PhotographerArea";
    import UserArea from "./UserArea";
    export default {
        name: "PersonalArea",
        components: {UserArea, PhotographerArea}
    }
</script>