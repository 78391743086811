<template>
    <ul class="social-auth-nav">
        <!--                        <li><a href="http://google.com">Google</a></li>-->
        <!--                        <li><a href="http://apple.com">Apple</a></li>-->
        <li>
            <div provider="facebook" v-on:click="social('facebook')">Facebook</div>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "Social",
        methods: {
            social(type) {
                this.$auth.oauth2({
                    provider: type,
                    state: {
                        redirect_to: this.$route.fullPath
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>