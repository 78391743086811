<!--<template>-->
<!--  &nbsp;-->
<!--</template>-->

<script>
export default {
  name: "GooglePixel",
  mounted() {

  }
}
if (process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false') {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
          new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WQGR8B5');
}
</script>

<noscript v-if="process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false'">
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQGR8B5" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
</noscript>

<style scoped>

</style>