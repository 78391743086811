<template>
  <div>

<!--      <b-dropdown variant="" toggle-tag="div" right toggle-class="nav-lang" menu-class="nav-lang-dropdown" no-caret>-->
<!--          <template v-slot:button-content>-->
<!--              <div class="nav-lang-icon"></div>-->
<!--          </template>-->
<!--          <b-dropdown-item v-on:click="change('ru')" :class="{active: $store.state.user.options.locale === 'ru'}">Русский</b-dropdown-item>-->
<!--          <b-dropdown-item v-on:click="change('en')" :class="{active: $store.state.user.options.locale === 'en'}">English</b-dropdown-item>-->
<!--      </b-dropdown>-->
    <v-google-translate
        @select="change"
        :defaultLanguageCode="$store.state.user.options.locale"
        defaultPageLanguageCode= "ru"
        :fetchBrowserLanguage="false"
        :languages="languages"
    />


    <!--        :defaultLanguageCode="defaultLanguageCode"-->
    <!--        defaultPageLanguageCode="zh-CN"-->
    <!--        :languages="languages"-->
    <!--        @select="languageSelectedHandler"-->

  </div>
</template>

<script>
import VueApp from "../../main";

export default {
  name: "SelectLanguage",
  mounted() {

  },
  data() {
    return {
      defaultLanguageCode: "en",

      languages:[
        {
          code: "en",
          name: "English",
          cname: "英语",
          ename: "English",
        },
        {
          code: "ar",
          name: "العربية",
          cname: "阿拉伯语",
          ename: "Arabic",
        },
        {
          code: "es",
          name: "Español",
          cname: "西班牙语",
          ename: "Spanish",
        },

        {
          code: "ru",
          name: "Русский",
          cname: "俄语",
          ename: "Russian",
        },
        {
          code: "it",
          name: "Italiano",
          cname: "意大利语",
          ename: "Italian",
        },
        {
          code: "pt",
          name: "Português",
          cname: "葡萄牙语",
          ename: "Portuguese",
        },
      ]
    };
  },
  methods: {
    languageSelectedHandler(info) {
      // console.log(info.code);
    },
    change(locale) {
      // this.$root.$emit('load-start');
      // this.$store.commit('user/setLang', locale.code)
    }
  }
}
</script>

<style scoped>

</style>