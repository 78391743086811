<template>
    <div class="row">
        <div class="col-12 col-xl-4 d-none d-xl-block">
            <div class="account-sidebar">
                <div class="account-sidebar-block" v-if="isPhotographer()">
                    <div class="btn btn-bordered btn-block" v-if="!$auth.user().is_verified">Аккаунт не подтвержден</div>
                </div>
                <div class="account-sidebar-block">
                    <p class="h2">Заказы</p>
                    <ul class="account-sidebar-nav" v-if="isPhotographer()">
                        <li v-if="isPhotographer()">
                            <a class="active" href="#orders-1">Горящие заявки</a>
                            <div class="label">
                                <div class="count hot">{{sortedOrders.hot.length}}</div>
                            </div>
                        </li>
                        <li>
                            <a href="#orders-2">Новые заказы</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.new.length}}</div>
                            </div>
                        </li>
                        <li v-if="isPhotographer">
                            <a href="#orders-3">Принятые</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.start.length}}</div>
                            </div>
                        </li>
                        <li>
                            <a href="#orders-4">В обработке</a>
                            <div class="label">
                                <div class="count problem">{{sortedOrders.processing.length}}</div>
                            </div>
                        </li>
                        <li>
                            <a href="#orders-5">Просроченные</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.expiring.length}}</div>
                            </div>
                        </li>
                        <li>
                            <a href="#orders-6">Завершённые</a>
                            <div class="label">
                                <div class="count">{{sortedOrders.finish.length}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="account-sidebar-block border-top">
                    <ul class="account-sidebar-nav">
                        <li>
                            <router-link :to="{name:'chats.area'}">Сообщения</router-link>
<!--                            <div class="label">-->
<!--                                <div class="count">4</div>-->
<!--                            </div>-->
                        </li>
                        <li>
                            <router-link  :to="{name:'order.area'}">Заявки</router-link>
<!--                            <div class="label">-->
<!--                                <div class="count">24</div>-->
<!--                            </div>-->
                        </li>
                    </ul>
                </div>
                <div class="account-sidebar-block">
                    <router-link :to="{name:'personal.area'}" class="btn btn-primary btn-text-medium btn-block">Перейти в профиль</router-link>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-8" >
            <div class="inner-content">
                <div class="disable-page-content" v-if="this.towns.length === 0">
                    Необходимо заполнить страницу профиля
                </div>
                <div>

                <p class="h2">Настройка приема заказов</p>
                <p class="h2">Прием онлайн заказов</p>
                <photographer-location></photographer-location>
                
                <p class="h2">Мой календарь</p>
                <div class="city-chooser">
                    <div class="row">
                        <div class="col-12 col-md-6"
                             v-bind:key="town.city.id"
                             v-on:click="selectedCity = town.city"
                             v-for="town in towns">
                            <div
                                    class="custom-control custom-radio custom-radio-block custom-radio-block-xl custom-radio-block-light"
                                    :class="{active:selectedCity.id === town.city.id}"
                            >
                                <input type="radio" :checked="selectedCity.id === town.city.id" :id="'city-choose-'+town.city.id" class="custom-control-input">
                                <label :for="'city-choose-'+town.city.id" class="custom-control-label">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
	                                        {{town.city[$store.state.user.options.locale]}}
	                                        <span v-if="town.is_main"> (основной)</span>
	                                    </div>
                                        <div class="count active">{{town.count_orders}}</div>
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="shadowed-block">
                    <div class="row">
                    	<div class="col-12 border-bottom col-md-6 border-md-right border-md-bottom-0 travels">
                            <datepicker
                                    :inline="true"
                                    :language="languages[$store.state.user.options.locale]"
                                    :fullMonthName="true"
                                    :highlighted="highlighted"
                                    :disabledDates="calendarDisableDates({is_main:true, calendar:calendar})"
                                    v-model="calendarDate"
                                    :mondayFirst="true"
                                    class="vdp-datepicker-inline"
                                    :class="{inverted:isMain()}"></datepicker>

                        </div>
                        <time-calendar ref="timeCalendar" :date="calendarDate" :orders="calendarOrders" :non-working-hours="calendarNonWorkingHours"></time-calendar>
                    </div>
                    <hr>
                  <div class="form-group">
                    <button class="btn btn-secondary btn-block btn-text-medium" v-if="calendarDate" v-on:click="addNonWorkingHours()" >
                    	Добавить занятое время на {{ calendarDate | moment("DD.MM.YYYY") }}
                    </button>
                    <b-modal id="modal-NonWorkingHours" centered hide-header hide-footer>
                      <div class="modal-header">
                        <p class="modal-title modal-brand">Выбрать время</p>
                        <div class="close" @click="$bvModal.hide('modal-NonWorkingHours')"></div>
                      </div>
                      <div class="form-group">
                        <div class="form-row">
                          <div class="col-6">
                            <vue-timepicker format="HH:mm" v-model="nonWorkingHour.start_time" :minute-interval="30"
                                            class="time-picker-sm" hide-clear-button></vue-timepicker>
                          </div>
                          <div class="col-6">
                            <vue-timepicker format="HH:mm" v-model="nonWorkingHour.end_time" :minute-interval="30"
                                            class="time-picker-sm" hide-clear-button></vue-timepicker>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-secondary btn-block" v-on:click="setNonWorkingHour()">Добавить</button>
                    </b-modal>
                  </div>
                  <button class="btn btn-primary btn-block btn-text-medium" v-on:click="saveJournal()">Сохранить
                    изменения
                  </button>
                </div>

                <div class="shadowed-block">
                    <b-collapse class="p-sm" id="hint-collapse">
                        <p class="weight-900">Отключение приёма заказов и сообщений</p>
                        <p>Кликните на дате, чтобы выбрать выключить прием заказов, планируйте свой месяц заранее, если у вас есть несколько городов приема заказов, тогда дни приема заказов будут выключены в других города в момент нахождения в городе проживания.</p>
                        <ul>
                            <li>Отметьте дни прибывания в других города что бы получить возможность получать заказы в это время</li>
                        </ul>
                        <p class="weight-900">Важно!</p>
                        <ul>
                            <li>Доступные для бронирования даты будут белыми в календаре.</li>
                            <li>Недоступные даты будут серыми, если они автоматически заблокированы, или будут иметь косую черту, если вы заблокировали их вручную.</li>
                            <li>Даты активные в одном городе будут не активны в других.</li>
                            <li>Кликните на Сохранить изменения после редактирования календаря.</li>
                        </ul>
                        <hr>
                    </b-collapse>
                    <div class="text-right">
                        <b-button v-b-toggle.hint-collapse class="collapse-link collapse-link-bottom">
                            <span class="text-collapsed">Показать подсказки</span><span class="text-collapse">Скрыть подсказки</span></b-button>
                    </div>
                </div>
                </div>
                <hr class="xl" v-if="isPhotographer()">
                <orders id="orders-collapse-hot" v-if="isPhotographer()" :orders="sortedOrders.hot" title="Горящие заявки" :notification="{}"></orders>

                <hr class="xl" v-if="isPhotographer()">
                <orders id="orders-collapse-start" :orders="sortedOrders.start.concat(sortedOrders.payment)" title="Новые заказы"></orders>

                <hr class="xl">
                <orders id="orders-collapse-confirmation" :orders="sortedOrders.confirmation" title="Принятые"></orders>
                <hr class="xl">
                <orders id="orders-collapse-processing" :orders="sortedOrders.processing" title="В обработке"></orders>

                <hr class="xl">
                <orders id="orders-collapse-expiring" :orders="sortedOrders.expiring" title="Просроченные"></orders>

                <hr class="xl">
                <orders id="orders-collapse-finish" :orders="sortedOrders.finish" title="Завершенные заказы"></orders>

                <hr class="xl">
                <orders id="orders-collapse-cancel" :orders="sortedOrders.cancel" title="Отмененные заказы"></orders>

            </div>
        </div>
    </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import * as lang from "vuejs-datepicker/dist/locale";
import Api from '../../service';
import moment from 'moment'
import TimeCalendar from "../../../widgets/TimeCalendar";
import Orders from "../widget/order/Orders";
import PhotographerLocation from "../../../widgets/PhotographerLocation";
import VueTimepicker from 'vue2-timepicker/src'

let api = undefined;
export default {
    name: "OrderPhotographer",
    components: {PhotographerLocation, Orders, TimeCalendar, datepicker, VueTimepicker},
    data() {
        return {
            today: undefined,
            calendarDate: '',
            selectedCity: undefined,
            mainCity: undefined,
            languages: lang,
            highlighted: {
                dates: [],
            },
            calendar:[],
            orders: [],
            nonWorkingHours: [],
            nonWorkingHoursToDelete: [],
            calendarOrders: [],
            calendarNonWorkingHours: [],
            nonWorkingHour: {
              start_time: {HH: '08', mm: '00'},
              end_time: {HH: '09', mm: '00'}
            },
            travelDates: [],
            towns: [],
            sortedOrders: {
                start: [],
                payment: [],
                confirmation: [],
                processing: [],
                issuing: [],
                expiring: [],
                new: [],
                hot: [],
                finish: [],
                cancel: [],
            },

        }
    },
    methods: {
        isMain(){
          if (typeof this.selectedCity == "undefined" || typeof this.mainCity == "undefined") {
            return false;
          }
          return this.selectedCity.id === this.mainCity.id;
        },
        loadCities() {
            api
                .getCities()
                .then((res) => {
                    if(res.data.data.length > 0){
                        this.selectedCity = this.mainCity = res.data.data.find(arr =>
                                arr.is_main === true
                        ).city;
                        this.towns = res.data.data;
                    }
                });

        },
        loadOrders() {
            api
                .getOrders()
                .then((res) => {
                    this.orders = res.data.data;
                    this.sortingOrders(res.data.data);
                });
        },
        loadNonWorkingHours(){
          api
              .getNonWorkingHours()
              .then((res) => {
                this.nonWorkingHours = res.data.data;
                this.fetchNonWorkHours(this.calendarDate);
              });
        },
        loadOrdersByTown() {
            api
                .getOrdersBytTown(this.selectedCity.id)
                .then((res) => {
                    this.orders = res.data.data;
                    this.sortingOrders(res.data.data);
                });
        },
        addNonWorkingHours(){
          this.$bvModal.show('modal-NonWorkingHours');
        },
        setNonWorkingHour() {
          this.$bvModal.hide('modal-NonWorkingHours');
          let selectedDay = this.clone(this.calendarDate);
          let start = moment(selectedDay).toDate();
          let end = moment(selectedDay).toDate();
          start.setHours(this.nonWorkingHour.start_time.HH);
          start.setMinutes(this.nonWorkingHour.start_time.mm);
          end.setHours(this.nonWorkingHour.end_time.HH);
          end.setMinutes(this.nonWorkingHour.end_time.mm);
          const tempId = Math.random();
          this.calendarNonWorkingHours.push(
              {
                start: start,
                end: end,
                tempId: tempId
              }
          );

          this.nonWorkingHours.push(
              {
                date: moment(selectedDay).format('YYYY-MM-DD'),
                tempId: tempId,
                start: this.nonWorkingHour.start_time.HH+':'+this.nonWorkingHour.start_time.mm,
                end: this.nonWorkingHour.end_time.HH+':'+this.nonWorkingHour.end_time.mm
              }
          )
        },
        saveJournal() {
          if (this.travelDates.length > 0) {
            api
                .updateTravels(this.selectedCity.id, this.travelDates)
                .then((res) => {
                  this.$root.$emit('successful', 'Дневник обновлен');
                });
          }

          if(this.nonWorkingHours || this.nonWorkingHoursToDelete){
            this.nonWorkingHours.forEach((hour) => {
              if(typeof hour.tempId !== "undefined"){
                delete hour.tempId;
              }
            })
            api
                .updateNonWorkingHours(this.nonWorkingHours, this.nonWorkingHoursToDelete)
                .then((res) => {
                  this.$root.$emit('successful', 'Дневник обновлен');
                  this.loadNonWorkingHours();
                });
          }

        },
        sortingOrders(orders) {
            this.sortedOrders =  {
                start: [],
                payment: [],
                confirmation: [],
                processing: [],
                issuing: [],
                expiring: [],
                new: [],
                hot: [],
                finish: [],
                cancel: []
            };
            orders.forEach((order) => {
                let isCancel = order.is_canceled;

                if (isCancel) {
                  this.sortedOrders.cancel.push(order);
                } else {

                  this.sortedOrders[order.status.code].push(order);
                  let date = moment(order.photo_finished);
                  let dateDiffInDays = date.diff(this.today, 'days');
                  let isFinished = order.status.code === 'finish';

                  if (dateDiffInDays <= 3 && dateDiffInDays > 0 && !isFinished) {
                    this.sortedOrders.hot.push(order);
                  }

                  if (dateDiffInDays <= 0 && !isFinished) {
                    this.sortedOrders.expiring.push(order);
                  }
                }

            });
        },
        loadDates() {
            api
                .getTravels(this.selectedCity.id)
                .then((res) => {
                    this.calendar = res.data.data.calendar;
                    this.travelDates = [];
                    this.highlighted.dates = [];
                    res.data.data.travels.forEach((date) => {
                        this.highlighted.dates.push(new Date(date.date));
                        this.travelDates.push({date:moment(date.date).format('YYYY-MM-DD'), is_day_off:date.is_day_off});
                    });
                });
        },
      fetchNonWorkHours(date){
        this.calendarNonWorkingHours = [];
        let formattedDate = moment(date).format('YYYY-MM-DD')
        this.nonWorkingHours.forEach((hour)=>{
          let start = moment(hour.date).format('YYYY-MM-DD');
          if(start === formattedDate){
            let date = moment(hour.date);
            let start = moment(date).toDate();
            let end = moment(date).toDate();
            start.setHours(hour.start.split(':')[0]);
            start.setMinutes(hour.start.split(':')[1]);
            end.setHours(hour.end.split(':')[0]);
            end.setMinutes(hour.end.split(':')[1]);
            //формат бэка неудачный
            this.calendarNonWorkingHours.push(
                {
                  id: hour.id,
                  start: start,
                  end: end
                }
            );
            // this.calendarNonWorkingHours.push(hour);
          }
        });
      }
    },
    watch: {
        selectedCity: {
            handler: function () {
                if (this.isPhotographer()) {
                    this.loadDates();
                    this.loadOrdersByTown();
                    this.loadNonWorkingHours();
                }else{
                    this.loadOrders();
                }
            },
            deep: true
        },
        calendarDate: {

            handler: function (newValue, oldVal) {
                if(newValue !== '' && moment(newValue).format('YYYY-MM-DD') === moment(oldVal).format('YYYY-MM-DD')){
                    let selectedDates = this.highlighted.dates.filter(date => moment(date).format('YYYY-MM-DD')  === moment(newValue).format('YYYY-MM-DD'));

                    if (selectedDates.length > 0) {
                        let index = this.highlighted.dates.findIndex(date =>  moment(date).format('YYYY-MM-DD')  === moment(newValue).format('YYYY-MM-DD'));
                        this.highlighted.dates.splice(index, 1);
                        this.travelDates.splice(index, 1);
                    } else {
                        this.highlighted.dates.push(newValue);
                        const date = moment(newValue).format('YYYY-MM-DD');
                        this.travelDates.push({date:date, is_day_off:false});
                    }
                }

                let selected = moment(newValue).format('YYYY-MM-DD');
                this.calendarOrders = [];
                this.orders.forEach((order)=>{
                    let start = moment(order.start).format('YYYY-MM-DD');
                    if(start === selected){
                        this.calendarOrders.push(order);
                    }
                });
                this.fetchNonWorkHours(selected);
                // this.calendarNonWorkingHours = [];
                // this.nonWorkingHours.forEach((hour)=>{
                //     let start = moment(hour.date).format('YYYY-MM-DD');
                //     if(start === selected){
                //       let date = moment(hour.date);
                //       let start = moment(date).toDate();
                //       let end = moment(date).toDate();
                //       start.setTime(hour.start);
                //       end.setTime(hour.end);
                //       // start.setHours(hour.start.HH);
                //       // start.setMinutes(hour.start.mm);
                //       // end.setHours(hour.end.HH);
                //       // end.setMinutes(hour.end.mm);
                //       this.calendarNonWorkingHours.push(
                //           {
                //             start: start,
                //             end: end
                //           }
                //       );
                //       this.calendarNonWorkingHours.push(hour);
                //     }
                //   });

                this.$forceUpdate();
            },
            deep: true
        }
    },
    mounted() {
        api = new Api();
        this.today = moment();
        this.calendarDate = moment().toDate();
        this.$root.$on('cancel_order',()=>{
          this.loadOrders();
        });
        if(this.isPhotographer()){
            this.loadCities();
        }else{
        //     this.loadCities();
            this.loadOrders();
        }
        this.$refs.timeCalendar.$on('deleteHour', (deleteHour)=>{
          this.nonWorkingHours.forEach( (hour, key) => {

            if(typeof deleteHour.id !== "undefined" && hour.id === deleteHour.id){
              this.nonWorkingHours.splice(key, 1);
              this.nonWorkingHoursToDelete.push(deleteHour.id);

            }else if (typeof deleteHour.tempId !== "undefined" && hour.tempId === deleteHour.tempId) {
              this.nonWorkingHours.splice(key, 1);
            }
            this.fetchNonWorkHours(this.calendarDate);
          })
        })
    }
}
</script>