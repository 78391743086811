// import axios from 'axios';
import Api from '../services';
import hash from 'object-hash';

export default class SearchApi extends Api{
    getFilters(object){
        const params = new URLSearchParams();

        for (let key in object) {
            if (object.hasOwnProperty(key) && object[key] ) {
               if(Array.isArray(object[key])){
                   if( object[key].length > 0){
                       params.append(key, object[key])
                   }
               }else{
                   params.append(key, object[key])
               }

            }
        }
        let hashedParams = hash(JSON.stringify(params));
        params.append('searchHash', hashedParams);
        return this.web.get('/search/filters',{
            params: params
        });
    }
    search(object){
        const params = new URLSearchParams();

        for (let key in object) {
            if (object.hasOwnProperty(key) && object[key] ) {
                if(Array.isArray(object[key])){
                    if( object[key].length > 0){
                        params.append(key, object[key])
                    }
                }else{
                    params.append(key, object[key])
                }

            }
        }
        return this.web.get('/search',{
            params: params
        });
    }
}