<template>
    <div class="order-block" id="orders-2">
        <div class="order-block-header d-flex justify-content-between">
            <div>
                <p class="h2">{{title}} <span class="green">{{orders.length}}</span></p>
            </div>
            <div v-if="orders.length > 0">
                <b-button  v-b-toggle="id"  class="orders-collapse-button">
                    <span class="text-collapsed">Показать</span><span class="text-collapse">Скрыть</span>
                </b-button>
            </div>
        </div>
        <b-collapse :id="id" v-if="orders.length > 0">
            <order-block v-bind:key="order.id" :class="{hide:params.limit < key + 1}" v-for="(order, key) in orders" :order="order"></order-block>
            <button class="btn btn-secondary btn-block btn-uppercase btn-text-light" v-if="orders.length > 1 &&  params.limit !== orders.length" v-on:click="params.limit++">Показать больше</button>
        </b-collapse>
        <!--            TODO на доработку!-->
        <div class="order-notifications d-flex justify-content-between align-content-center" v-if="notification">
            <div class="order-notifications-text">
                Хотите получать уведомления о срочных заказах
            </div>
            <div class="custom-control custom-switch custom-switch-right">
                <input type="checkbox" class="custom-control-input" id="order-notifications-1">
                <label class="custom-control-label custom-control-label-green" for="order-notifications-1">Получать!</label>
            </div>
        </div>
    </div>
</template>

<script>
import OrderBlock from "./OrderBlock";
export default {
    name: "Orders",
    props:{
        notification:{
            type:Object,
            default: ()=>{
                return undefined
            }
        },
        params:{
          type: Object,
          default:   ()=>{
              return {
                  limit: 5
              }
          }
        },
        orders:{
            type:Array
        },
        title:{
            type:String
        },
        id:{
            type:String,
            default: 'orders-collapse'
        }
    },
    data(){
        return{

        }
    },

    components: {OrderBlock}
}
</script>

<style scoped>
.hide{
    display: none;
}
</style>