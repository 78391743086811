<template>

  <div class="inner-content">
    <div class="messages-list-block">
      <p class="h2">Сообщения</p>

      <router-link :to="{name:'chat.area',params:{id:chat.order.number}}" class="messages-list-item"
                   v-for="chat in chats">
        <div class="d-flex">
          <div>
            <div class="avatar" :style="{
                                            backgroundImage: `url(${chat.user.avatars['thumb-166']})`,
                                            }"></div>
          </div>
          <div class="d-md-flex w-100 justify-content-between align-items-center">
            <div class="d-flex flex-md-column">
              <div class="status">Пользователь</div>
              <div class="name ml-2 ml-md-0">{{ getName(chat.user) }}</div>
            </div>
            <div class="info">
              Съемка {{ chat.order.start_date | moment("DD MMMM YYYY в hh:mm") }} <br>
              {{ chat.order.city[$store.state.user.options.locale]}} <br>
              Стоимость: {{ chat.order.price }} <i class="rub">Р</i>
            </div>
          </div>
        </div>
      </router-link>

    </div>
    <!--        <div class="messages-list-block">-->
    <!--            <p class="h2">Старые сообщения</p>-->
    <!--            <a href="page-messages-item.php" class="messages-list-item">-->
    <!--                <div class="d-flex">-->
    <!--                    <div>-->
    <!--                        <div class="avatar" style="background-image: url(/temp/photo-1.jpg)"></div>-->
    <!--                    </div>-->
    <!--                    <div class="d-md-flex w-100 justify-content-between align-items-center">-->
    <!--                        <div>-->
    <!--                            <div class="status">Пользователь</div>-->
    <!--                            <div class="name">Михаил Алекссев</div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </a>-->
    <!--&lt;!&ndash;            <button class="btn btn-secondary btn-text-medium btn-uppercase btn-block">Показать больше</button>&ndash;&gt;-->
    <!--        </div>-->
  </div>
</template>

<script>
import ChatAreaApi from "./service";

let api = new ChatAreaApi();
export default {
  name: "ChatList",
  data() {
    return {
      chats: []
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      api.getChats().then((res) => {
        this.chats = res.data.data;
      })
    }
  }

}
</script>

<style scoped>

</style>