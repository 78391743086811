<template>
  <div class="reserve-photographer d-xl-none">
    <div class="row align-items-center">
      <div class="col-6 col-md-8">
        <strong>Забронировать фотографа сейчас <span class="d-none d-md-inline">от 3000 рублей/ час</span></strong>
      </div>
      <div class="col-6 col-md-4">
        <button class="btn btn-primary btn-block" v-b-modal.modal-reserve-photographer>Забронировать</button>
        <short-order ref="short_order" :order="order" :price="price" :order-data="orderData" :durations="durations"

                     :user="user" :calendar="calendar"></short-order>
      </div>
    </div>
  </div>
</template>

<script>
import ShortOrder from "../modals/ShortOrder";

export default {
  name: "MobileOrder",
  components: {ShortOrder},
  props: {
    order: {
      type: Object
    },
    price: {
      type: Object
    },
    orderData: {
      type: Object
    },

    durations: {
      type: Array
    },
    user: {
      type: Object
    },
    calendar: {
      type: Object
    }
  },
  mounted() {

    this.$refs.short_order.$on('book', () => {
      this.$emit('book');
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>