import Vue from "vue";

const Role = {
    Admin: 'admin',
    Client: 'client',
    Photographer: 'photographer',
    Partner: 'partner',
};
export default Role;


Vue.mixin({
    methods: {
        isPhoneVerified(){
            return this.$auth.user().is_phone_confirmed;
        },
        isClient(){
            return this.$auth.user().role === Role.Client;
        },
        isPhotographer(){
            return this.$auth.user().role === Role.Photographer;
        },
        isPartner(){
            return this.$auth.user().role === Role.Partner;
        },
        clone(obj){
            return JSON.parse(JSON.stringify(obj))
        }
    }
})
