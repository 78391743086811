<template>
    <div class="autocomplete-wrapper">
        <GmapAutocomplete
                ref="autocomplete"
                :selectFirstOnEnter="true"
                @place_changed="setPlace"
                :options="options"
                :value="getPlaceName()"
                :placeholder="$t('base.search_placeholder')"
                v-on:keyup.13="clickEnter()"
                class="form-control form-icon icon-location light"
        ></GmapAutocomplete>
        <div class="clear" v-on:click="clear()"></div>
    </div>
</template>

<script>
export default {
    name: "Autocomplete",
    data() {
        return {
            is_first_pres: true,
            is_block: true,
            options: {
                // types: ['(cities)'],
            }
        }
    },
    mounted() {

    },
    watch: {},
    methods: {
        clickEnter() {
            setTimeout(() => {
                        //TODO вроде не нужна эта тарабарщина с ферст кликом
                        if (this.is_first_pres) {
                            this.is_first_pres = false;
                            this.$emit('place-enter')
                        } else {
                            this.is_first_pres = false;
                            if (this.is_placed) {
                                this.is_placed = false;
                            } else {
                                this.$emit('place-enter')
                            }
                        }
                    }
                    , 500)

        },
        setPlace(place) {
            localStorage.setItem('user.store.place_raw_legacy', JSON.stringify(place));
            if (this.is_first_pres && this.$place.getRawData().formatted_address === place.formatted_address) {
                return;
            }
            this.is_placed = true;
            this.is_first_pres = false;
            this.$place.setRawData(place);
        },
        clear() {
            this.$place.clear()
            this.$forceUpdate();
        },
        getPlaceName() {
            return this.$place.getName();
        },

        getRaw() {
            return this.$place.getRawData();
        }
    }
}
</script>

<style scoped>

</style>