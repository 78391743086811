<script>
export default {
  name: "Cookie",
  data(){
    return {
      showAlert:true
    }
  },
  mounted() {
    if(localStorage.getItem('allow_cookie')){
      this.showAlert = false;
    }
  },
  methods:{
    setAllowCookie(){
      localStorage.setItem('allow_cookie', '1');
      this.showAlert = false;
    }
  }
}
</script>

<template>
  <div class="footer-cookie" v-if="showAlert">
    <div class="text">
      Наш сайт использует куки для улучшения пользовательского опыта. Нажимая на эту кнопку, вы соглашаетесь с нашей политикой конфиденциальности. <a href="/rules/">Посмотреть условия</a>
    </div>
    <div class="button">
      <button class="btn btn-secondary btn-xs" v-on:click="setAllowCookie">Принимаю</button>
    </div>
  </div>
</template>

<style scoped>

</style>