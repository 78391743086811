<template>
    <b-dropdown variant="" toggle-tag="div" right toggle-class="nav-currency" menu-class="nav-currency-dropdown" no-caret>
        <template v-slot:button-content>
            <div class="nav-currency-icon"></div>
        </template>
        <b-dropdown-item class="active"><span class="rub">Р</span> Российский рубль</b-dropdown-item>
<!--        <b-dropdown-item><span>$</span> Доллар США</b-dropdown-item>-->
    </b-dropdown>
</template>

<script>

export default {
    name: "SelectCurrency"
}
</script>

<style scoped>

</style>