
export default {
    namespaced: true,

    state: {
        layout: undefined,
        mobileMenu: false,
        width: window.innerWidth
    },
    mutations: {
        SET_WIDTH (state, value) {
            state.width = value
        },
        set(state, payload) {
            state.layout = payload
        },
        menu(state) {
            document.body.classList.remove("menu-open");
            state.mobileMenu = false;
        }
    },
    actions: {

    },
    getters: {
        layout(state) {
            return state.layout
        }
    }
}