<template>
    <order-photographer v-if="isPhotographer()"></order-photographer>
    <order-client v-else></order-client>
</template>

<script>
import OrderPhotographer from "./OrderPhotographer";
import OrderClient from "./OrderClient";
export default {
    name: "Order",
    components: {OrderClient, OrderPhotographer}
}
</script>

<style scoped>

</style>