<!--<template>-->

<!--</template>-->

<script>

export default {
  name: "FBPixel"
}
if (process.env.VUE_APP_METRIKA_DEBUG_MODE === 'false') {
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '646617062704088');
  fbq('track', 'PageView');
}

</script>

<noscript><img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=646617062704088&ev=PageView&noscript=1"
/></noscript>

<style scoped>

</style>