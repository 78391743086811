// import axios from 'axios';
import Api from '../services';

export default class MainApi extends Api{
  photographer(){
    return this.web.get('/photographer');
  }

  categories(){
    return this.web.get('/category');
  }

  prices(){
    return this.web.get('/payout');
  }

  albums(category, limit, offset){

      const params = new URLSearchParams();
      if(category !== 'all'){
          params.append('category', category);

      }
      params.append('limit', limit);
      params.append('offset', offset);

    return this.web.get('/albums', {
      params: params
    });
  }
}