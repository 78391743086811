<template>
<div>
 <payment-form :config="config"></payment-form>
<!--  <button v-on:click="pay()">asd</button>-->
</div>
</template>
<script>
import PaymentForm from "@/components/payment/PaymentForm.vue";

export default {
  name: "Form",
  components: {PaymentForm},
  data() {
    return {
      config: {}
    }
  },
  mounted() {
    console.log('mounted');
    this.$root.$emit('load-modules', {});
    setTimeout(()=>{
      console.log(1);
      this.config = {
        instrument: 'cloud',
        widget:{
          publicId: 'pk_c677d5399b1d5da8ca556d9cf3719', //id из личного кабинета
                description: 'Оплата товаров в snappy', //назначение
              amount: 100, //сумма
              currency: 'RUB', //валюта
              accountId: 'kriokris@gmail.com', //идентификатор плательщика (необязательно)
              invoiceId: 'asdqwe1234', //номер заказа  (необязательно)
              email: 'kriokris@gmail.com', //email плательщика (необязательно)
              skin: "mini", //дизайн виджета (необязательно)
              autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
              data: {
            myProp: 'myProp value'
          },
            configuration: {
              common: {
                successRedirectUrl: "https://snappy.sealed.company/success", // адреса для перенаправления
                    failRedirectUrl: "https://snappy.sealed.company/fail"        // при оплате по T-Pay
              }
            },
            payer: {
              firstName: 'Тест',
                  lastName: 'Тестов',
                  middleName: 'Тестович',
                  birth: '1955-02-24',
                  address: 'тестовый проезд дом тест',
                  street: 'Lenina',
                  city: 'MO',
                  country: 'RU',
                  phone: '123',
                  postcode: '345'
            }
        }
      };
    },1);
  },
  methods:{
  }
}
</script>
<style scoped>

</style>