<template>
	<div>
		<p>Где вы сейчас находитесь? Локация действует 60 мин.</p>
		<div class="form-group">
			<div class="input-group">
				<div class="input-group-prepend">
					<div v-on:click="detect2()" class="btn btn-primary btn-icon icon-get-location"></div>
				</div>
			
				<div class="autocomplete-wrapper">
					<GmapAutocomplete
						ref="autocomplete"
						:selectFirstOnEnter="true"
						@place_changed="setPlace"
						:value="getPlaceName()"
						:options="options"
						:placeholder="$t('base.search_placeholder')"
						class="form-control"
						></GmapAutocomplete>
					<!--      <div class="clear" v-on:click="clear()"></div>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Api from "../services";

let api = new Api();
export default {
  name: "PhotographerLocation",
  data(){
    return{
      loading: false,
      address: '',
      options: {
        // types: ['(cities)'],
      }
    }
  },
  mounted() {

    if(localStorage.getItem('photographer_address')){
      this.address = localStorage.getItem('photographer_address');
    }
  },
  methods:{
    getPlaceName() {
      if (this.loading) {
        return '...';
      }
      return this.address;
    },
    saveAddress(address){
      localStorage.setItem('photographer_address',address);
      this.address = address;
      this.$forceUpdate();
    },
    setPlace(data){
      let coords = JSON.parse(JSON.stringify(data.geometry.location))
      this.saveAddress(data.formatted_address);
      this.saveCoordinate(coords.lat, coords.lng)
    },
    saveCoordinate(lat,lng){


      if(!this.loading){
        this.loading = true;
        api.saveCoordinates(lat,lng).then((data)=>{
          this.loading = false;
        })
      }

    },
    detect2() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((data) => {
          //   console.log(123);
          this.saveCoordinate(data.coords.latitude, data.coords.longitude)
          this.getAddress(data.coords.latitude, data.coords.longitude);
        }, (er) => {
          this.detect();
        }, {
          enableHighAccuracy: true
          , timeout: 500
        })
      } else {
        this.detect();
      }
    },
    detect(){
      let url = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + process.env.VUE_APP_GOOGLE_API_KEY;
      const options = {
        method: 'POST',
        headers: { 'content-type': 'text/plain' },
        data: '',
        url,
      };
      axios(options).then((data)=>{
        this.saveCoordinate(data.data.location.lat, data.data.location.lng);
        this.getAddress(data.data.location.lat,data.data.location.lng);
      });

    },
    getAddress(lat, lng){

    api.geoAddress({lat:lat, lng:lng}).then((data)=>{
      this.saveAddress(data.data.data.address);
    })
      // let url = 'https://maps.googleapis.com/maps/api/geocode/json?result_type=street_address&latlng='+lat+','+lng+'&key=' + process.env.VUE_APP_GOOGLE_API_KEY;
      // const options = {
      //   method: 'POST',
      //   headers: { 'content-type': 'text/plain' },
      //   data: '',
      //   url,
      // };
      // axios(options).then((data)=>{
      //   this.saveAddress(data.data.plus_code.compound_code);
      // });
    }
  }
}
</script>

<style scoped>

</style>