import Api from '../services';

export default class NotifyApi extends Api {
    getNotify(){
        return this.web.get('/notifications');
    }
    getNewNotify(date){
        const params = new URLSearchParams();
        params.append('created_at', date)
        return this.web.get('/notifications', {
            params: params
        });
    }
    getOrders(){
        return this.web.get('/personal/order?status[]=1&status[]=2');
    }
}