<template>
    <div class="loader" v-bind:class="{'in-progress':loadInProgress}">
		<div class="spinner-border text-primary"></div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        data(){
          return{
              loadInProgress: true
          }
        },
        beforeCreate(){
            let self = this;
            self.$root.$on('load-start', function () {
                self.loadInProgress = true;
            });
            self.$root.$on('load-modules',  (modules)=> {
                if (!modules){
                    self.loadInProgress = false;
                }else{
                    let moduleValues = Object.values(modules);
                    let notLoadedModule = moduleValues.filter(value => value === false);
                    if(notLoadedModule.length === 0){
                        self.loadInProgress = false;
                    }
                }

            });
        },
    }
</script>

<style scoped>
    .loader {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
        visibility:  hidden;
        opacity: 0;
        z-index: 999;
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        -webkit-transition: all .5s ease-out;
           -moz-transition: all .5s ease-out;
            -ms-transition: all .5s ease-out;
             -o-transition: all .5s ease-out;
                transition: all .5s ease-out;
    }
    .in-progress{
        background: #ffffff;
        opacity: 1;
        visibility: visible;
    }
    .in-progress .windows8{
        display: block;
    }
</style>