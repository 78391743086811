<template>
  <div id="account-buttons-block">
    <div id="account-buttons">

      <div class="row">
        <slot></slot>
<!--        <div class="col-12 col-md-6">-->
<!--          <div class="form-group">-->
<!--              <span href="#"-->
<!--                    class="btn btn-secondary btn-block btn-uppercase">{{ $t('lk.personal_area.cancel') }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 col-md-6">-->
<!--          <div class="form-group">-->
<!--              <span class="btn btn-primary btn-block btn-uppercase pointer "-->
<!--                    :class="{'disabled' : isEqual(user, old_user)}"-->
<!--                    v-on:click="save()">{{ $t('lk.personal_area.save') }}</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedButtonPanel",
  mounted() {
    this.$on('load', this.load);
  },
  methods:{
    load(){
        let block = document.getElementById('account-buttons-block'),
            buttons = document.getElementById('account-buttons'),
            buttonsHeight = buttons.offsetHeight,
            buttonsWidth = buttons.offsetWidth,
            blockTop = block.getBoundingClientRect().top + pageYOffset;

        if ((pageYOffset + document.documentElement.clientHeight) < (blockTop + buttonsHeight)) {
          block.style.height = buttonsHeight + 'px';
          buttons.style.width = buttonsWidth + 'px';
          buttons.classList.add('fixed');
        }

        window.addEventListener('scroll', function () {
          if ((pageYOffset + document.documentElement.clientHeight) < (blockTop + buttonsHeight)) {
            block.style.height = buttonsHeight + 'px';
            buttons.style.width = buttonsWidth + 'px';
            buttons.classList.add('fixed');
          } else {
            block.removeAttribute('style');
            buttons.removeAttribute('style');
            buttons.classList.remove('fixed');
          }
        });
    }
  }
}
</script>

<style scoped>

</style>