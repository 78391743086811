<template>
    <div class="inner-content">
        <p class="h2">{{$t('lk.notification_area.notification')}}</p>
        <div class="row align-items-center" v-if="isPhotographer()">
            <div class="col-12 col-md">
                <p class="p-sm"><strong v-html="$t('lk.notification_area.near')"></strong></p>
            </div>
            <div class="col-12 col-md-auto">
                <div class="form-group">
                    <div class="custom-control custom-checkbox custom-checkbox-block">
                        <input type="checkbox" id="notification-checkbox-1" maxlength="2" class="custom-control-input"  v-model="types.OrderNear">
                        <label for="notification-checkbox-1" class="custom-control-label">{{$t('lk.notification_area.type')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="account-separator-sm"></div>
        <div class="row align-items-center" v-if="isPhotographer()">
            <div class="col-12 col-md">
                <p class="p-sm"><strong v-html="$t('lk.notification_area.requests')"></strong></p>
            </div>
            <div class="col-12 col-md-auto">
                <div class="form-group">
                    <div class="custom-control custom-checkbox custom-checkbox-block">
                        <input type="checkbox" id="notification-checkbox-2" maxlength="2" class="custom-control-input" v-model="types.OrderRequest">
                        <label for="notification-checkbox-2" class="custom-control-label">{{$t('lk.notification_area.type')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="account-separator-sm"></div>
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <p class="p-sm"><strong v-html="$t('lk.notification_area.statuses')"></strong></p>
            </div>
            <div class="col-12 col-md-auto">
                <div class="form-group">
                    <div class="custom-control custom-checkbox custom-checkbox-block">
                        <input type="checkbox" id="notification-checkbox-3" maxlength="2" class="custom-control-input"  v-model="types.OrderStatus">
                        <label for="notification-checkbox-3" class="custom-control-label">{{$t('lk.notification_area.type')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="account-separator-sm"></div>
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <p class="p-sm"><strong v-html="$t('lk.notification_area.payment')"></strong></p>
            </div>
            <div class="col-12 col-md-auto">
                <div class="form-group">
                    <div class="custom-control custom-checkbox custom-checkbox-block">
                        <input type="checkbox" id="notification-checkbox-4" maxlength="2" class="custom-control-input"  v-model="types.PaymentStatus">
                        <label for="notification-checkbox-4" class="custom-control-label">{{$t('lk.notification_area.type')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="account-separator-sm"></div>
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <p class="p-sm"><strong v-html="$t('lk.notification_area.feedbacks')"></strong></p>
            </div>
            <div class="col-12 col-md-auto">
                <div class="form-group">
                    <div class="custom-control custom-checkbox custom-checkbox-block">
                        <input type="checkbox" id="notification-checkbox-5" maxlength="2" class="custom-control-input"  v-model="types.NewMessage">
                        <label for="notification-checkbox-5" class="custom-control-label">{{$t('lk.notification_area.type')}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalAreaApi from "../../service";
let api = new PersonalAreaApi();

export default {
    name: "Notification",
    data(){
      return {
          is_ready: false,
          types:{
                'OrderNear' : false,
                'OrderRequest' : false,
                'OrderStatus' : false,
                'PaymentStatus' : false,
                'NewMessage' : false
          }
      }
    },
    watch: {
        'types.OrderNear': {
            handler: function (newValue, oldVal) {
                this.toggle('OrderNear');
            },
            deep: true
        },
        'types.OrderRequest': {
            handler: function (newValue, oldVal) {
                this.toggle('OrderRequest');
            },
            deep: true
        },
        'types.OrderStatus': {
            handler: function (newValue, oldVal) {
                this.toggle('OrderStatus');
            },
            deep: true
        },
        'types.PaymentStatus': {
            handler: function (newValue, oldVal) {
                this.toggle('PaymentStatus');
            },
            deep: true
        },
        'types.NewMessage': {
            handler: function (newValue, oldVal) {
                this.toggle('NewMessage');
            },
            deep: true
        },
    },
    mounted() {

        api.getNotify().then((res)=>{
            res.data.data.forEach((nType)=>{
                this.types[nType.type] = true;
            })
            setTimeout(()=>{
                this.is_ready = true;
            }, 100);
        })
    },
    methods:{
        toggle(type){
            if(this.is_ready){
                api.toggleNotifyType(type);
            }
        }
    }
}
</script>

<style scoped>

</style>