

export default {
    namespaced: true,

    state: {
        categories:[],
        locations:[],
        sessions:[],
        cities:[],
        additional_services:[],
        people_counter:[],
        equipment:[],
        languages:[],
        photo_readiness:[],
    },
    mutations: {

    },
    actions: {},
    getters: {}

}