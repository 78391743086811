<template>
  <b-modal :id="modal_id" centered hide-header hide-footer>
    <div class="modal-header">
      <p class="modal-title modal-title-sm">Подтверждение телефона</p>
      <div class="close" v-if="phone_key === ''" @click="hide"></div>
    </div>
    <div class="form-group">
      <label>Код подтверждения:</label>
      <input type="text" v-model="code" class="form-control form-control-sm" placeholder="Введите код">
    </div>
    <div class="form-group">
      <span v-if="timer > 0">Отправить повторно через: {{ timer }} секунд</span>
      <button class="btn btn-secondary btn-block" v-else v-on:click="getNewCode()">Отправить повторно</button>
    </div>
    <div class="form-group" v-if="has_error">
      <span>Ошибка проверочного кода</span>
    </div>
    <button type="submit" class="btn btn-primary btn-block" v-on:click="confirm()">Подтвердить</button>
  </b-modal>
</template>

<script>
import PersonalAreaApi from "@/components/lk/service";
import Api from "@/components/services";

let api = undefined;
export default {
  name: "ConfirmPhone",
  props: {
    modal_id: {
      type: String,
      default: 'modal-confirm-phone'
    }
  },
  data() {
    return {
      code: '',
      timer: 30,
      has_error: false,
      phone_key: false,
      timer_interval: undefined
    }
  },
  mounted() {
    },
  methods: {
    getApi(){
      if(this.phone_key !== false){
        api = new Api();
      }else{
        api = new PersonalAreaApi();
      }
      return api;
    },
    hide() {
      this.$bvModal.hide(this.modal_id);
    },
    show(obj) {
      if(obj.phone_key){
        this.phone_key = obj.phone_key;
      }



      this.$bvModal.show(this.modal_id);
      this.timer = obj.timer;

      if (this.isWait()) {
        this.startTimer();
      }
    },
    startTimer() {
      this.timer_interval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(this.timer_interval);
        }
      }, 1000)
    },
    isWait() {
      return this.timer > 0;
    },
    getNewCode() {
        this.getApi()
            .getNewConfirmCode(this.phone_key)
            .then((res) => {
              this.timer = res.data.data.phone_confirmation.resend_life_time === 0 ? 30 : res.data.data.phone_confirmation.resend_life_time;
              if (this.isWait()) {
                this.startTimer();
              }
            })
    },
    confirm() {
      this.getApi()
          .confirmPhone(this.code, this.phone_key)
          .then((res) => {
            if (res.data.data.check) {
              this.hide();
              this.$auth.user().is_phone_confirmed = true;
            } else {
              this.$root.$emit('error', 'Не верный код подтверждения');
            }
          })
          .then((err) => {
            // this.$root.$emit('error', 'Не верный код подтверждения');
          })
    }
  }
}
</script>

<style scoped>

</style>