<template>
    <div class="col-12 col-md-6">
		<p class="day-schedule-title" v-if="date">График на {{ date | moment("DD.MM.YYYY") }}</p>

		<div class="day-schedule" :class="{'day-schedule-short':date}">
            <div class="day-schedule-scroll">
                <div class="content">

                    <div class="event nonWorkingHours" v-bind:style="{top: getStart(hour.start)+'em', height:getLong(hour.end, hour.start)+'em'}" v-for="hour in nonWorkingHours">
                        <div class="time">{{ hour.start | moment("HH:mm") }}–{{ hour.end | moment("HH:mm") }}</div>
                        <div class="delete" v-on:click="deleteHour(hour)">Удалить</div>
                    </div>
                    <div class="event" v-bind:style="{top: getStart(order.start_date)+'em', height:getLong(order.end_date, order.start_date)+'em'}" v-for="order in orders">
                        <div class="avatar" :style="{
                            backgroundImage: `url(${order.user.avatars['thumb-166']})`,
                            }"></div>
                        <div class="time">{{ order.start_date | moment("HH:mm") }}–{{ order.end_date | moment("HH:mm") }}</div>
                    </div>
                    <div class="half-hour" v-for="time in templateRanges">
                        <div class="time">{{time}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "TimeCalendar",
    props:{
       is_full:{
           type:Boolean,
           default: false
       },
        orders:{
           type:Array
        },
        nonWorkingHours:{
           type:Array
        },
        date: {
	        type:String
        }
    },
    data(){
      return{
          templateRanges:[],
          startHour: 8
      }
    },
    methods:{
        deleteHour(hour){
          this.$emit('deleteHour', hour);
        },
        getStart(date){
          return   moment(date).format('HH') - this.startHour
        },
        getLong(date, date_diff){
            // console.log(1 / moment(order.end_date).diff(order.start_date, 'hours'));
            // console.log( moment(order.end_date).diff(order.start_date, 'hours'));
            return   moment(date).diff(date_diff, 'hours');
        },
        generateTime(hour){
            // let hour = this.startHour;
            this.templateRanges = [];
            for(hour; hour < 24; hour++) {
                this.templateRanges.push(moment({ hour }).format('HH:mm'));
                this.templateRanges.push(
                    moment({
                        hour,
                        minute: 30
                    }).format('HH:mm')
                );
            }
        },
    },
    mounted() {
        this.generateTime(this.startHour);
    }
}
</script>

<style scoped>

</style>