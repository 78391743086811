<template>
    <div class="inner-content">
        <div class="messages-list-block">
            <p class="h2">Сообщения от пользователя</p>

             <order-block :order="order" v-if="order"></order-block>
        </div>

        <div class="messages-list-block">
            <p class="weight-500">Чат с клиентом</p>
            <div class="form-group">
                <textarea v-model="message" class="form-control" placeholder="Напишите сообщение"></textarea>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <button class="btn btn-primary btn-block" v-on:click="send()" :disabled="wait">Отправить</button>
                </div>
            </div>
        </div>
        <div class="support-feed">
            <div class="support-feed-item" v-for="chat_message in messages">
                <div class="content">
                    <div class="avatar" :style="{
                                            backgroundImage: `url(${chat_message.user.avatars['thumb-166']})`,
                                            }"></div>
                    <div class="info">
                        <div class="d-flex w-100 justify-content-between">
                            <div class="role">{{ chat_message.user.id === $auth.user().id ? $t('lk.chat_area.its_my') : $t(chat_message.user.role) }}</div>
                            <div class="date">{{ chat_message.created_at | moment("from", "now") }}</div>
                        </div>
                        <div class="d-flex w-100 justify-content-between justify-content-md-start">
                            <div class="name">{{ getName(chat_message.user)}}</div>
                        </div>
                    </div>
                    <div class="text">
                        {{ chat_message.message }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import OrderBlock from "../lk/components/widget/order/OrderBlock";
import ChatAreaApi from "./service";
import PersonalAreaApi from "../lk/service";
import moment from 'moment';

let api = new ChatAreaApi();
let personalApi = new PersonalAreaApi();
export default {
    name: "Chat",
    components: {OrderBlock},
    data() {
        return {
            wait: false,
            message: '',
            messages: [],
            last_update: moment(),
            order: undefined
        }
    },
    mounted() {
        this.load();
        setInterval(()=>{
            this.update()
        }, 60000);
    },
    methods: {
        load() {
            personalApi
                    .getOrder(this.$route.params.id)
            .then((res)=>{
                this.order = res.data.data.order;
            });
            api.getChat(this.$route.params.id).then((res) => {
                this.messages = res.data.data;
            });
        },
        update(){
            api.getNewMessages(this.$route.params.id, this.last_update.format('DD.MM.YYYY HH:mm:ss')).then((res) => {
                if(res.data.data.length > 0){
                    this.messages.unshift(res.data.data);
                }
                this.last_update = moment();
            })
        },
        send() {
            this.wait = true;
            api.createNewMessage(this.$route.params.id, this.message).then((res) => {
                this.message = '';
                this.messages.unshift(res.data.data.message);
                this.wait = false;
                this.last_update = moment();
            });
        }
    },
}
</script>

<style scoped>

</style>