<template>
  <div class="container">
    <div class="inner-content">
      <div class="about-promo about-promo-bg d-flex justify-content-center align-items-center">
        <div class="content">
          <p class="title">О Snappy</p>
        </div>
      </div>
      <div class="front-about">
        <p>Snappy — сервис быстрого поиска фотографов. Мы работает по всей России и собираемся за ее границы.</p>
        <p>Помогаем клиентам найти фотографов именно в тот самый момент (и ни минутой дольше), когда эмоции хочется
          сохранить в кадре. </p>
        <p>А фотографам не отвлекаться от творчества на вопросы поиска клиентов и раскрутки себя на рынке. Фотографы,
          помните – пока вы ищете новых клиентов, где-то грустит клиент, ожидающий от вас обработанных фото...</p>

        <hr class="xl">

        <h2>Это мы провели всероссийский флэшмоб фотографов #ЗапомниЭтоЛето</h2>
        <p>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/_l6R_pyWWkE" frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
        </p>
        <p>1500+ счастливых лиц и трогательных эмоций сняли по всей стране от Калининграда до Владивостока фотографы
          Snappy 11-12 июля 2020 г. Это наш подарок 💚 клиентам к открытию beta-теста площадки.</p>

        <hr class="xl">

        <h2>Команда Snappy</h2>
        <div class="row">
          <div class="col-12 col-xl-4">
            <div class="command">
              <div>
                <div class="photo" style="background-image: url(/assets/images/command/irina.jpg)"></div>
              </div>
              <div class="info">
                <p class="name">Ира Рыжкова</p>
                <p class="position">Основатель Snappy</p>
                <ul class="links">
                  <li><a href="mailto:ira@snappy.me"></a></li>
                  <li><a href="https://t.me/ryzhkovaira" target="_blank" rel="nofollow"></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4">
            <div class="command">
              <div>
                <div class="photo" style="background-image: url(/assets/images/command/anton.jpg)"></div>
              </div>
              <div class="info">
                <p class="name">Антон Бельцевич</p>
                <p class="position">Генеральный директор, со-основатель</p>
                <ul class="links">
                  <li><a href="mailto:anton@snappy.me"></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4">
            <div class="command">
              <div>
                <div class="photo" style="background-image: url(/assets/images/command/daria.jpg)"></div>
              </div>
              <div class="info">
                <p class="name">Даша Акимова</p>
                <p class="position">Директор по маркетингу, со-основатель</p>
                <ul class="links">
                  <li><a href="mailto:daria@snappy.me"></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr class="xl">

        <h2>Давай общаться!</h2>
        <p>Наши группы с социальных сетях</p>
        <p>
        <ul class="nav-social d-flex align-items-center">
          <li><a href="https://vk.com/snappy_photoshoot" target="_blank" rel="nofollow">vk</a></li>
          <li><a href="https://www.facebook.com/Snappy-100457625023854" target="_blank" rel="nofollow">facebook</a></li>
          <li><a href="https://www.instagram.com/snappy_photoshoot/" target="_blank" rel="nofollow">instagram</a></li>
          <li><a href="https://www.youtube.com/channel/UCX-SfQrRNA-GShfR99t63Kw/" target="_blank"
                 rel="nofollow">youtube</a></li>
        </ul>
        </p>
        <p>Чат поддержки фотографов и клиентов</p>
        <p><a href="https://t.me/snappy_me" target="_blank" rel="nofollow">Telegram</a></p>
        <p>Почта </p>
        <p><a href="mailto:hello@snappy.me">hello@snappy.me</a></p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>