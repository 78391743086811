module.exports = {

    request: function (req, token) {
        this.options.http._setHeaders.call(this, req, {'X-Auth-Token': 'Bearer ' + token});
    },

    response: function (res) {
        var headers = this.options.http._getHeaders.call(this, res),
            token = headers['X-Auth-Token'] || headers['x-auth-token'];
        if (token) {
            token = token.split(/Bearer\:?\s?/i);

            return token[token.length > 1 ? 1 : 0].trim();
        }
    }
};