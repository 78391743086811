import {i18n} from '@/i18n'
import moment from 'moment'

export default {
    namespaced: true,

    state: {
        auth: false,
        token: "",
        availableSocials: [
            'FB'
        ],
        profile: {
            avatars: []
        },
        fill: {},
        options: {
            gLocale: 'en',
            locale: 'ru',
            city: undefined,
        },
    },
    mutations: {
        auth(state, token) {
            if (!token) {
                return null;
            }
            if (token) {
                state.auth = true;
                state.token = token;
            }
        },
        load(state, user) {
            if (state.auth) {
                state.profile = user.personal;
                state.fill = user.fill;
            }
        },
        logout(state) {
            state.auth = false;
            state.profile = {};
        },
        options(state, options) {
            state.options = options;
        },
        setCity(state, city) {
            state.options.city = city;
            this.commit('user/options', state.options);
        },
        setLang(state, locale) {

            // i18n.locale = locale;
            // moment.locale(locale);
            state.options.gLocale = locale;
            // this.commit('user/options', state.options);
            // setTimeout(() => {
            //     location.reload();
            // }, 100)
        },

        init(state) {
            if (localStorage.getItem('user.store.options')) {
                let options = JSON.parse(localStorage.getItem('user.store.options'));
                if (!options.city) {
                    options.city = {id: 'ChIJybDUc_xKtUYRTM9XV8zWRD0', en: "Moskva", ru: "Москва"};
                }
                if (!options.locale) {
                    options.locale = 'ru';
                }
                state.options = options;
                i18n.locale = state.options.locale; //TODo
                moment.locale(state.options.locale);
                moment.locale(state.options.locale, {
                    calendar: {
                        lastDay: function () {
                            return state.options.locale === 'ru' ? '[Вчера]' : '[Yesterday]';
                        },
                        sameDay: function () {
                            return state.options.locale === 'ru' ? '[Сегодня]' : '[Today]';
                        },
                        lastWeek: function () {
                            return 'L';
                        }
                    }
                })
            }
        }
    },
    actions: {},
    getters: {
        avatar(state) {
            return state.profile.avatars
        }
    }
}