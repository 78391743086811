<template>
  <div class="main-content flex-shrink-0">
    <div class="container">
      {{ text }}
    </div>
  </div>
</template>

<script>
import Api from '../services';

let api = new Api();
export default {
  name: "Verification",
  data() {
    return {
      code: this.$route.params.code,
      text: 'Ожидайте идет подтверждение'
    }
  },
  mounted() {
    if (this.code) {
      api
          .verification(this.code)
          .then((res) => {
            this.$router.push({path: '/'});
            this.text = 'Переадресация'
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
          })
    } else {
      /**
       * TODO
       */
    }
  }
}
</script>

<style scoped>

</style>